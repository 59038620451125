import React from "react";
import "./Form.scss";

import { useTranslation } from "react-i18next";

import DInputs from "../../../../Form/DInputs/DInputs";
import FormFooter from "./FormFooter/FormFooter";
import FormHeader from "./FormHeader/FormHeader";
import DDatepicker from "./../../../../Form/DDatepicker/DDatepicker";
import DCurrencyCode from "../../../../Form/DSelect/DCurrencyCode/DCurrencyCode";
import DCheckBox from "../../../../Form/DCheckBox/DCheckBox";
import DTextarea from "./../../../../Form/DTextarea/DTextarea";
import DPhoneInput from "../../../../Form/DPhoneInput/DPhoneInput";
import ChargeForm from "./ChargeForm/ChargeForm";
import BankRequisitesFormItem from "./BankRequisitesFormItem/BankRequisitesFormItem";
import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";
import { FiPlus } from "react-icons/fi";
import { useSelector } from "react-redux";

const Form = ({
  order,
  submit,
  loading,
  name,
  setName,
  date,
  setDate,
  currrencyCode,
  setCurrrencyCode,
  bankRequisites,
  setBankRequisites,
  transaction,
  setTransaction,
  invoiceWarning,
  setInvoiceWarning,
  invoiceDayNumber,
  setInvoiceDayNumber,
  email,
  setEmail,
  phone,
  setPhone,
  phoneIndex,
  setPhoneIndex,
  address,
  setAddress,
  comment,
  setComment,
  charges,
  setCharges,
}) => {
  const { t } = useTranslation();

  const { createInvoiceError } = useSelector((state) => state.invoices);

  return (
    <div id='invoiceForm'>
      <FormHeader order={order} />
      <form className='form'>
        <DInputs
          id={"Invoicetitle"}
          placeholder={t("tms.invoice.INC-59383293")}
          label={t("tms.invoice.Invoice title")}
          value={name}
          setValue={setName}
          required
          error={createInvoiceError?.errors?.["invoice.number"]?.[0]}
        />
        <div className='row'>
          <DDatepicker
            required
            label={t("tms.invoice.Issue date")}
            value={date}
            setValue={setDate}
            error={createInvoiceError?.errors?.["invoice.date"]?.[0]}
          />
          <DCurrencyCode
            required
            value={currrencyCode}
            setValue={setCurrrencyCode}
            id='DCurrencyCodeSelectInvoicee'
            label={t("tms.invoice.Currency")}
            error={createInvoiceError?.errors?.["invoice.currency"]?.[0]}
          />
        </div>
        <ChargeForm charges={charges} setCharges={setCharges} />

        <div className='bankRequisitesCont'>
          <div className='sectiontitle'>
            <h2>{t("tms.invoice.Bank requisite")}</h2>
          </div>
          {bankRequisites.map((item, index) => (
            <BankRequisitesFormItem
              key={index}
              bankRequisites={bankRequisites}
              setBankRequisites={setBankRequisites}
              item={item}
              index={index}
            />
          ))}
          <div className='addRequisite'>
            <DTextButton
              title={t("tms.invoice.Add another requisite")}
              rightIcon={<FiPlus />}
              primary
              onClick={() => {
                setBankRequisites([
                  ...bankRequisites,
                  {
                    bankName: "",
                    bankCode: "",
                    bankACCN: "",
                  },
                ]);
              }}
            />
          </div>
        </div>

        <DCheckBox
          value={transaction}
          setValue={setTransaction}
          label={t("tms.invoice.Ask customer Include invoice number on transaction")}
        />
        <DCheckBox
          value={invoiceWarning}
          setValue={setInvoiceWarning}
          label={t("tms.invoice.Mention how many days after you want this invoice to be paid")}
        />
        {invoiceWarning && (
          <div className='row'>
            <DInputs
              type='number'
              id={"InvoicetobepaidInput"}
              placeholder={t("tms.invoice.Number of days")}
              label={t("tms.invoice.Invoice to be paid (days)")}
              value={invoiceDayNumber}
              setValue={setInvoiceDayNumber}
              error={null}
            />
            <div style={{ width: "100%" }}></div>
          </div>
        )}

        <div className='sectiontitle'>
          <h2>{t("tms.invoice.Other information")}</h2>
        </div>
        <DInputs
          type='email'
          id='InvoicemailInput'
          placeholder={t("tms.invoice.Enter your email")}
          label={t("tms.invoice.Your Mail")}
          value={email}
          setValue={setEmail}
          error={null}
        />
        <DPhoneInput
          id='invoicePhoneInput'
          placeholder={t("tms.invoice.Enter your phone")}
          label={t("tms.invoice.Your Mobile")}
          error={null}
          type='number'
          value={phone}
          setValue={setPhone}
          indexValue={phoneIndex}
          setIndexValue={setPhoneIndex}
        />
        <DInputs
          id='InvoiAddressInput'
          placeholder={t("tms.invoice.Enter your address")}
          label={t("tms.invoice.Your address")}
          value={address}
          setValue={setAddress}
          error={null}
        />
        <DTextarea
          id='invoiceCommentValue'
          value={comment}
          setValue={setComment}
          error={null}
          placeholder={t("tms.invoice.Your text area ...")}
          label={t("tms.invoice.Comment")}
        />
      </form>
      <FormFooter submit={submit} loading={loading} />
    </div>
  );
};

export default Form;
