import React, { useState, useEffect, useMemo } from "react";
import "./DirectionShipperMapModal.scss";

import { GoogleMap, useJsApiLoader, DirectionsRenderer } from "@react-google-maps/api";

import { settings } from "./mapSettings";

import Overlay from "./../../../../componentsLanding/Popup/Overlay/Overlay";
import LoadingPage from "./../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import TrackingShipperHeader from "./TrackingShipperHeader/TrackingShipperHeader";
import MarkerC from "./Markers/MarkerC";
import MarkerA from "./Markers/MarkerA";
import MarkerB from "./Markers/MarkerB";

const DirectionShipperMapModal = ({ isOpen, isClose, order }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const [directions, setDirections] = useState(null);

  const locations = order?.cargos?.[0]?.locations ?? [];

  const cargos = order?.cargos?.[0]?.locations ?? [];
  const filterA = cargos.filter((i) => i.direction === "A");
  const filterB = cargos.filter((i) => i.direction === "B");

  const currentLocation = useMemo(() => {
    return (
      order?.last_shipment_status_update?.location && {
        lat: parseFloat(order.last_shipment_status_update.location.lat),
        lng: parseFloat(order.last_shipment_status_update.location.lng),
      }
    );
  }, [order?.last_shipment_status_update?.location]);

  const [first, ...middleElements] = locations;
  const last = middleElements.pop();
  const waypoints = useMemo(
    () =>
      middleElements.map((waypoint) => ({
        location: { lat: parseFloat(waypoint.lat), lng: parseFloat(waypoint.lng) },
        stopover: true, // Optionally add stopover
      })),
    [middleElements]
  );

  const origin = useMemo(
    () => ({
      lat: parseFloat(first.lat),
      lng: parseFloat(first.lng),
    }),
    [first]
  );

  const destination = useMemo(
    () => ({
      lat: parseFloat(last.lat),
      lng: parseFloat(last.lng),
    }),
    [last]
  );

  useEffect(() => {
    if (!directions && isLoaded && window.google) {
      const directionsService = new window.google.maps.DirectionsService();
      const updatedWaypoints = [...waypoints];
      if (currentLocation) {
        if (
          currentLocation.lat !== origin.lat ||
          (currentLocation.lng !== origin.lng && currentLocation.lat !== destination.lat) ||
          currentLocation.lng !== destination.lng
        ) {
          updatedWaypoints.push({
            location: currentLocation,
            stopover: true,
          });
        }
      }

      directionsService.route(
        {
          origin: origin, // Start point
          destination: destination, // End point (Tbilisi)
          travelMode: window.google.maps.TravelMode.DRIVING, // Can be DRIVING, BICYCLING, TRANSIT, or WALKING
          waypoints: updatedWaypoints,
          optimizeWaypoints: true, // Optional: This will reorder waypoints for optimal routing
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result); // Store the route result
          }
        }
      );
    }
  }, [directions, currentLocation, destination, origin, waypoints, isLoaded]);

  return (
    <Overlay isOpen={isOpen} isClose={isClose}>
      <div id='DirectionShipperMapModal'>
        <TrackingShipperHeader order={order} isClose={isClose} />
        <div className='TrackingShipperMap'>
          {isLoaded && waypoints ? (
            <GoogleMap
              mapContainerStyle={settings.containerStyle}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                styles: [
                  {
                    featureType: "poi",
                    elementType: "labels",
                    stylers: [{ visibility: "off" }],
                  },
                  {
                    featureType: "poi.business",
                    stylers: [{ visibility: "off" }],
                  },
                ],
              }}
            >
              {directions && (
                <DirectionsRenderer
                  directions={directions}
                  options={{
                    polylineOptions: {
                      strokeColor: "#5C5CFF",
                      strokeOpacity: 0.6,
                      strokeWeight: 5,
                    },
                    suppressMarkers: true,
                  }}
                />
              )}

              {currentLocation && <MarkerC position={currentLocation} label='You' />}
              {filterA
                .filter((item) => item.location !== null)
                .map((item) => (
                  <MarkerA key={item.id} item={item} />
                ))}
              {filterB
                .filter((item) => item.location !== null)
                .map((item) => (
                  <MarkerB key={item.id} item={item} />
                ))}
            </GoogleMap>
          ) : (
            <LoadingPage />
          )}
        </div>
      </div>
    </Overlay>
  );
};

export default DirectionShipperMapModal;
