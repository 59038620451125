import React, { useState, Fragment } from "react";

import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";
import { findPhoneData } from "../../../../../helper/findPhoneData";

import DeletePartnerModal from "./../../DeletePartnerModal/DeletePartnerModal";
import EditPartnerModal from "./../../EditPartnerModal/EditPartnerModal";
import RowField from "../../../../Form/TableFields/RowField/RowField";
import TextField from "../../../../Form/TableFields/TextField/TextField";
import MoreField from "../../../../Form/TableFields/MoreField/MoreField";
import DateField from "../../../../Form/TableFields/DateField/DateField";
import PartnerField from "./../../../../Form/TableFields/PartnerField/PartnerField";
import RateField from "../../../../Form/TableFields/RateField/RateField";
import { useSelector } from "react-redux";

const PartnersTableItem = ({ item }) => {
  const { t } = useTranslation();
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const customerPhoneIndex = findPhoneData(getPhoneIndexesResult, item?.phone_index_id, true);

  return (
    <Fragment>
      <RowField>
        <PartnerField
          max={50}
          label={item?.name || "-"}
          to={`/dashboard/tms/customers_partners/partners/${item.id}`}
        />
        <TextField max={30} label={item?.code || "-"} />
        <TextField max={30} label={item?.email || "-"} />

        <RateField rate={item?.rating || 0} />

        <TextField label={item?.phone ? customerPhoneIndex + " " + item?.phone : "-"} />
        <DateField date={item?.last_order_date} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMorePartners'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>

      {openDeleteModal && (
        <DeletePartnerModal
          id={item.id}
          isOpen={openDeleteModal}
          isClose={() => setOpenDeleteModal(false)}
        />
      )}
      {openEditModal && (
        <EditPartnerModal
          item={item}
          isOpen={openEditModal}
          isClose={() => setOpenEditModal(false)}
        />
      )}
    </Fragment>
  );
};

export default PartnersTableItem;
