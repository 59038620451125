import React, { useState, useEffect } from "react";
import "./SignUpShipperAuthForm.scss";

import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

import { signupStep2, resetSignupStep2 } from "../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import TermsAgree from "../../../Form/Checkbox/TermsAgree/TermsAgree";
import PhoneInput from "../../../Form/Inputs/PhoneInput/PhoneInput";

const SignUpShipperAuthForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { signupStep2Loading, signupStep2Result, signupStep2Error } = useSelector(
    (state) => state.auth
  );
  const { phoneIndexValueId } = useSelector((state) => state.settings);

  const companyName = searchParams.get("companyName");
  const averageShipments = searchParams.get("averageShipments");
  const countryId = searchParams.get("countryId");
  const industryId = searchParams.get("industryId");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(true);

  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (signupStep2Result) {
      const url = `/register/shipper/verify?companyName=${companyName}&countryId=${countryId}&industryId=${industryId}&averageShipments=${averageShipments}&countryId=${countryId}&fullName=${fullName}&email=${email}&phone=${phone}&phoneIndexId=${phoneIndexValueId}&agree=${agree}`;
      dispatch(resetSignupStep2());
      navigate(url);
    }
  }, [
    navigate,
    dispatch,
    signupStep2Result,
    fullName,
    countryId,
    industryId,
    phone,
    companyName,
    email,
    agree,
    averageShipments,
    phoneIndexValueId,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetSignupStep2());
    };
  }, [dispatch]);

  const submitHandler = () => {
    let data = {
      type_id: 3,
      company: {
        name: companyName,
      },
      average_monthly_shipment: averageShipments,
      industry_id: industryId,
      branch_id: countryId,

      name: fullName,
      email: email,
      phone_index_id: phoneIndexValueId,
      phone: phone,
      password: password,
      password_confirmation: password,

      agreement: agree,
    };
    dispatch(signupStep2(data));
  };

  return (
    <AuthFormWrapper>
      <form id='SignUpShipperAuthForm' className='SignUpShipperAuthForm'>
        <FormTitle title={t("landing.auth.titles.Welcome to the future of logistics")}>
          <span>{t("landing.auth.titles.We almost there")}</span>
        </FormTitle>
        <ProgressBar position={2} />
        <div className='group'>
          <div>
            <Label text={t("landing.auth.form.Full name")} id='FullName' />
            <Input
              value={fullName}
              setValue={setFullName}
              placeholder={t("landing.auth.form.Enter full name")}
              id='FullName'
              error={signupStep2Error?.errors?.name?.[0] ?? null}
            />
            <ErrorText error={signupStep2Error?.errors?.name?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Email")} id='AuthEmail' />
            <Input
              value={email}
              setValue={setEmail}
              placeholder={t("landing.auth.form.Enter email")}
              id='AuthEmail'
              error={signupStep2Error?.errors?.email?.[0] ?? null}
            />
            <ErrorText error={signupStep2Error?.errors?.email?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Phone number")} id='PhoneNumber' />
            <PhoneInput
              id='PhoneNumber'
              value={phone}
              setValue={setPhone}
              placeholder={t("landing.auth.form.Enter Phone number")}
              error={
                (signupStep2Error?.errors?.phone_index_id?.[0] ?? null) ||
                (signupStep2Error?.errors?.phone?.[0] ?? null)
              }
            />
            <ErrorText error={signupStep2Error?.errors?.phone_index_id?.[0] ?? null} />
            <ErrorText error={signupStep2Error?.errors?.phone?.[0] ?? null} />
          </div>
          <div>
            <Label
              text={t("landing.auth.form.Create Password")}
              id='Password'
              warning
              warningText={t(
                "landing.form.warningText.password must contain minimum of 6 character"
              )}
            />
            <Input
              value={password}
              setValue={setPassword}
              placeholder={t("landing.auth.form.Enter password")}
              id='Password'
              password
              error={signupStep2Error?.errors?.password?.[0] ?? null}
            />
            <ErrorText error={signupStep2Error?.errors?.password?.[0] ?? null} />
          </div>
          <div>
            <TermsAgree value={agree} setValue={setAgree} />
            <ErrorText error={signupStep2Error?.errors?.agreement?.[0] ?? null} />
          </div>
        </div>

        <Button
          loading={signupStep2Loading}
          title={t("landing.auth.form.Next")}
          onClick={() => {
            submitHandler();
          }}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpShipperAuthForm;
