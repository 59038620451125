import React, { Fragment } from "react";
import "./ShipperTracking.scss";

import { useTranslation } from "react-i18next";

import TrackingHeader from "./TrackingHeader/TrackingHeader";
import TrackingDriverData from "./TrackingDriverData/TrackingDriverData";
import TrackingDistanceData from "./TrackingDistanceData/TrackingDistanceData";
import TrackingUpdatesData from "./TrackingUpdatesData/TrackingUpdatesData";

const ShipperTracking = ({ order }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      {order && (
        <div id='ShipperTrackingSection'>
          <TrackingHeader order={order} />
          {order?.transports?.length > 0 && <TrackingDriverData order={order} />}
          {order?.statusClient?.id >= 3 && order?.last_shipment_status_update ? (
            <Fragment>
              <TrackingDistanceData order={order} />
              <TrackingUpdatesData order={order} />
            </Fragment>
          ) : (
            <div className='noTracking'>
              <div
                className='icon'
                style={{
                  backgroundImage: `url(${`/assets/images/dashboard/NoTrackingIllustration.png`})`,
                }}
              ></div>
              <h1 className='title'>{t("shipper.desc.No tracking")}</h1>
              <p className='desc'>
                {t(
                  "shipper.desc.Tracking is currently unavailable as it hasn’t started yet. It will be displayed once we have enough data to provide accurate updates."
                )}
              </p>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default ShipperTracking;
