import React from "react";
import "./TrucksTooltip.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MdOutlinePhoneIphone, MdPerson } from "react-icons/md";
import { GoContainer } from "react-icons/go";
import { findPhoneData } from "../../../../helper/findPhoneData";

const TrucksTooltip = ({ data }) => {
  const { t } = useTranslation();

  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const driverPhoneIndex = findPhoneData(getPhoneIndexesResult, data?.driver?.phone_index_id, true);

  return (
    <div id='TrucksTooltip' className='PlanningAssistantTableTrucksTooltipContent'>
      <div className='driverContainer'>
        <div className='title'>{t("tms.titles.Driver")}</div>
        <div className='infoContainer'>
          <div className='infoField'>
            <div className='icon'>
              <MdPerson />
            </div>
            <div className='info'>
              <h3>{data?.driver?.name || "-"}</h3>
            </div>
          </div>
          <div className='infoField'>
            <div className='icon'>
              <MdOutlinePhoneIphone />
            </div>
            <div className='info'>
              <h3>{driverPhoneIndex + " " + data?.driver?.phone || "-"}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className='trailerContainer'>
        <div className='title'>{t("tms.titles.Trailer")}</div>
        <div className='infoContainer'>
          <div className='infoField'>
            <div className='icon'>
              <GoContainer />
            </div>
            <div className='info'>
              <h3>{`${data?.trailer?.type?.name || "-"} - ${
                data?.trailer?.volume_capacity
                  ? data?.trailer?.volume_capacity + t("shipper.units.M3")
                  : ""
              }`}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrucksTooltip;
