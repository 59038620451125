import React, { Fragment, useState, useEffect } from "react";
import "./EditDriversModal.scss";

import { useTranslation } from "react-i18next";
import { FiEdit2 } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { updateDriver, resetUpdateDriver } from "./../../../../store/fleet/drivers/driversSlice";

import DModal from "./../../../Modal/DModal/DModal";
import DInputs from "./../../../Form/DInputs/DInputs";
import DPhoneInput from "./../../../Form/DPhoneInput/DPhoneInput";
import { findPhoneData } from "../../../../helper/findPhoneData";

const EditDriversModal = ({ isOpen, isClose, driver }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateDriverLoading, updateDriverResult, updateDriverError } = useSelector(
    (state) => state.drivers
  );
  const { phoneIndexValueId, getPhoneIndexesResult } = useSelector((state) => state.settings);

  const [name, setName] = useState(driver?.name || "");
  const [phoneNumber, setPhoneNumber] = useState(driver.phone ? driver.phone : "");
  const [phoneIndex, setPhoneIndex] = useState(null);

  const submitHandler = () => {
    const data = {
      id: driver.id,
      name: name,
      phone: phoneNumber,
      phone_index_id: phoneIndex?.value?.id,
    };
    dispatch(updateDriver(data));
  };

  useEffect(() => {
    if (driver?.phone) {
      const phoneIndexObj = driver?.phone_index_id
        ? findPhoneData(getPhoneIndexesResult, driver?.phone_index_id)
        : findPhoneData(getPhoneIndexesResult, phoneIndexValueId);

      setPhoneIndex({
        value: phoneIndexObj,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundImage: `url(${phoneIndexObj?.flag})`,
                backgroundPosition: "cover",
                backgroundRepeat: "no-repeat",
                marginRight: "5px",
              }}
            ></div>
            <p>{phoneIndexObj?.phone_index}</p>
          </div>
        ),
      });
    }
  }, [driver, getPhoneIndexesResult, phoneIndexValueId]);

  useEffect(() => {
    if (updateDriverResult) {
      dispatch(resetUpdateDriver());
      isClose();
    }
  }, [dispatch, isClose, updateDriverResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Edit Driver")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiEdit2 />}
        status='success'
        onClick={() => submitHandler()}
        loading={updateDriverLoading}
      >
        <div id='EditDriversModal'>
          <div className='EditDriversModalGroup'>
            <DPhoneInput
              id='enterDriverPhoneAddDriver'
              label={t("tms.modals.labels.Phone number")}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              value={phoneNumber}
              required
              setValue={setPhoneNumber}
              indexValue={phoneIndex}
              setIndexValue={setPhoneIndex}
              error={
                (updateDriverError?.errors?.phone?.[0] ?? null) ||
                (updateDriverError?.errors?.phone_index_id?.[0] ?? null)
              }
            />
            <DInputs
              id='enterDriverFisrNameAddDriver'
              label={t("tms.modals.labels.Driver first name")}
              placeholder={t("tms.modals.placeholder.Enter first name")}
              value={name}
              setValue={setName}
              required
              error={updateDriverError?.errors?.name?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default EditDriversModal;
