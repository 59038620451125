import React, { Fragment } from "react";
import "./Lang.scss";

import { useSelector, useDispatch } from "react-redux";
import { MdLanguage } from "react-icons/md";

import { setLang } from "../../../store/additional/lang/langSlice";

const Lang = () => {
  const { ISO, getLanguagesResult } = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const RenderItem = ({ item }) => {
    return (
      <li
        onClick={() => {
          dispatch(setLang(item.key));
        }}
        className='item'
      >
        {item.native}
      </li>
    );
  };

  return (
    <Fragment>
      <div id='Lang' className='Lang'>
        <div className='langButton'>
          <div className='icon'>
            <MdLanguage />
          </div>
          {ISO && ISO !== "undefined" ? ISO.toUpperCase() : ""}
        </div>
        <ul className='langContent'>
          {getLanguagesResult &&
            getLanguagesResult.map((item) => <RenderItem key={item.key} item={item} />)}
        </ul>
      </div>
    </Fragment>
  );
};

export default Lang;
