import React, { Fragment, useState, useEffect } from "react";
import "./AssignPartnerTableItem.scss";

import { useTranslation } from "react-i18next";

import { MdLink } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import {
  assignCarrier,
  resetAssignCarrier,
} from "./../../../../../../../store/orders/manageOrder/manageOrderSlice";
import { findPhoneData } from "../../../../../../../helper/findPhoneData";

import RowField from "./../../../../../../Form/TableFields/RowField/RowField";
import TextField from "./../../../../../../Form/TableFields/TextField/TextField";
import CenterField from "./../../../../../../Form/TableFields/CenterField/CenterField";
import BidResponseField from "../../../Components/BidResponseField/BidResponseField";
import PartnerField from "./../../../../../../Form/TableFields/PartnerField/PartnerField";
import AssignPartnerButton from "./../../../Components/AssignPartnerButton/AssignPartnerButton";
import DModal from "../../../../../../Modal/DModal/DModal";
import RateField from "./../../../../../../Form/TableFields/RateField/RateField";
import DCurrencyInput from "./../../../../../../Form/DCurrencyInput/DCurrencyInput";

const AssignPartnerTableItem = ({
  setBidScreen,
  partner,
  isOpen,
  isClose,
  order,
  tab,
  setTab,
  partnerTab,
  setPartnerTab,
  page,
  setPage,
  partnersBids,
  setPartnersBids,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { assignCarrierLoading, assignCarrierResult, assignCarrierError } = useSelector(
    (state) => state.manageOrder
  );
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const bidPrice = partner?.bid?.amount || "0";

  const bidCurrency = partner?.bid?.currency || null;

  const [openAssign, setOpenAssign] = useState(false);

  const [expense, setExpense] = useState(bidPrice);
  const [expenseCurrency, setExpenseCurrency] = useState({
    label: bidCurrency ? bidCurrency.code : order?.expense_currency?.code,
    value: bidCurrency ? bidCurrency : order?.expense_currency,
  });

  const submitHandler = () => {
    const data = {
      orderId: order.id,

      partner_id: partner.id,
      expense: expense || "0",
      expense_currency_id: expenseCurrency?.value?.id || null,
    };
    dispatch(assignCarrier(data));
  };

  useEffect(() => {
    if (assignCarrierResult) {
      dispatch(resetAssignCarrier());
    }
  }, [dispatch, assignCarrierResult]);

  useEffect(() => {
    return () => {
      dispatch(resetAssignCarrier());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <RowField>
        <PartnerField label={partner?.name || "-"} />
        <RateField rate={partner?.rating || 0} />
        <TextField
          label={
            findPhoneData(getPhoneIndexesResult, partner?.phone_index_id, true) +
              " " +
              partner?.phone || "-"
          }
        />
        <TextField max={40} label={partner?.email || "-"} />
        <BidResponseField partner={partner} />
        <CenterField>
          <AssignPartnerButton
            title={t("tms.forms.buttons.Assign partner")}
            onClick={() => setOpenAssign(true)}
          />
        </CenterField>
      </RowField>
      {openAssign && (
        <DModal
          title={t("tms.modals.titles.Assign partner")}
          submitText={t("tms.modals.buttons.Confirm")}
          isOpen={openAssign}
          isClose={() => setOpenAssign(false)}
          onClick={() => submitHandler()}
          loading={assignCarrierLoading}
          icon={<MdLink />}
        >
          <div id='AssignPartnerTableModal'>
            <p>{t("tms.modals.desc.Before assign partner enter the partners price")}</p>
            <div className='curr'>
              <DCurrencyInput
                value={expense}
                setValue={setExpense}
                placeholder={t("tms.modals.placeholder.Enter partner price")}
                label={t("tms.modals.labels.Partner price")}
                currencyValue={expenseCurrency}
                setCurrencyValue={setExpenseCurrency}
                required
                id='FinancialDetailsPartnerPrice'
                error={
                  (assignCarrierError?.errors?.expense?.[0] ?? null) ||
                  (assignCarrierError?.errors?.expense_currency_id?.[0] ?? null)
                }
              />
            </div>
          </div>
        </DModal>
      )}
    </Fragment>
  );
};

export default AssignPartnerTableItem;
