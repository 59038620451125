import React, { useState, useEffect } from "react";
import "./SignUpShipperForm.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  signupShipperStep1,
  resetSignupShipperStep1,
} from "../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import SelectCountry from "../../../Form/Select/Components/SelectCountry/SelectCountry";
import NotHaveAccount from "./../../../Form/NotHaveAccount/NotHaveAccount";
import SelectIndustry from "../../../Form/Select/Components/SelectIndustry/SelectIndustry";
import SelectForm from "../../../Form/Select/SelectForm";

const monthlyShipmentsOptions = [
  { value: "1-4", label: "1-4" },
  { value: "5-20", label: "5-20" },
  { value: "21-50", label: "21-50" },
  { value: "51-100", label: "51-100" },
  { value: ">101", label: ">101" },
];

const SignUpShipperForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { signupShipperStep1Loading, signupShipperStep1Result, signupShipperStep1Error } =
    useSelector((state) => state.auth);

  const { countryId } = useSelector((state) => state.settings);
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState(null);
  const [averageShipments, setAverageShipments] = useState(null);

  useEffect(() => {
    if (signupShipperStep1Result) {
      const url = `/register/shipper/auth?companyName=${companyName}&industryId=${industry?.value?.id}&averageShipments=${averageShipments?.value}&countryId=${countryId}&`;
      navigate(url);
      dispatch(resetSignupShipperStep1());
    }
  }, [
    dispatch,
    navigate,
    averageShipments,
    companyName,
    countryId,
    industry,
    signupShipperStep1Result,
  ]);

  useEffect(() => {
    return () => {
      dispatch(resetSignupShipperStep1());
    };
  }, [dispatch]);

  const submitHandler = () => {
    let data = {
      type_id: 3,
      company: {
        name: companyName,
      },
      branch_id: countryId,
      industry_id: industry?.value?.id,
      average_monthly_shipment: averageShipments?.value,
    };
    dispatch(signupShipperStep1(data));
  };

  return (
    <AuthFormWrapper>
      <form id='SignUpShipperForm' className='SignUpShipperForm'>
        <FormTitle title={t("landing.auth.titles.Welcome to the future of logistics")}>
          <span>{t("landing.auth.titles.It just take 2 minutes to set up")}</span>
        </FormTitle>
        <ProgressBar />
        <div className='group'>
          <div>
            <Label text={t("landing.auth.form.Company name")} id='CompanyName' />
            <Input
              value={companyName}
              setValue={setCompanyName}
              placeholder={t("landing.auth.form.Enter company name")}
              id='CompanyName'
              error={signupShipperStep1Error?.errors?.["company.name"]?.[0] ?? null}
            />
            <ErrorText error={signupShipperStep1Error?.errors?.["company.name"]?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Industry")} />
            <SelectIndustry
              value={industry}
              setValue={setIndustry}
              error={signupShipperStep1Error?.errors?.industry_id?.[0]}
            />
            <ErrorText error={signupShipperStep1Error?.errors?.industry_id?.[0] ?? null} />
          </div>
          <div>
            <Label
              text={t("landing.auth.form.Average number of monthly shipments")}
              id='MonthlyShipments'
            />
            <SelectForm
              id='MonthlyShipments'
              name='MonthlyShipments'
              options={monthlyShipmentsOptions}
              placeholder={t("landing.auth.form.Select number")}
              value={averageShipments}
              setValue={setAverageShipments}
              error={signupShipperStep1Error?.errors?.average_monthly_shipment?.[0] ?? null}
            />
            <ErrorText
              error={signupShipperStep1Error?.errors?.average_monthly_shipment?.[0] ?? null}
            />
          </div>
          <div>
            <Label text={t("landing.auth.form.Country")} />
            <SelectCountry
              placeholder={t("landing.auth.form.Choose Country")}
              error={signupShipperStep1Error?.errors?.branch_id?.[0] ?? null}
            />
            <ErrorText error={signupShipperStep1Error?.errors?.branch_id?.[0] ?? null} />
          </div>
        </div>
        <Button
          loading={signupShipperStep1Loading}
          title={t("landing.auth.form.Next")}
          onClick={() => {
            submitHandler();
          }}
        />
        <NotHaveAccount
          desc={t("landing.auth.titles.Already have an account?")}
          link='/login'
          text={t("landing.auth.form.Sign in here")}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpShipperForm;
