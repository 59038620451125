import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

// 1, 'SUPER_ADMIN'
// 2, 'INDIVIDUAL_DRIVER'
// 3, 'LOGISTRICS_COMPANY_DRIVER'
// 4, 'LOGISTRICS_COMPANY'
// 5, 'SENDER'

// | 1 |  CRM User
// | 2 |  Platform Admin
// | 3 |  Client
// | 4 |  Logistics Company
// | 5 |  Logistics Company Driver
// | 6 |  Individual Driver

// getClientLoading: true,
// getClientResult: null,
// getClientError: null,

const access_token = JSON.parse(localStorage.getItem("access_token"));

const initialState = {
  access_token: access_token ? access_token : null,

  loginLoading: false,
  loginResult: null,
  loginError: null,

  signupShipperStep1Loading: false,
  signupShipperStep1Result: null,
  signupShipperStep1Error: null,

  signupCompanyStep1Loading: false,
  signupCompanyStep1Result: null,
  signupCompanyStep1Error: null,

  signupStep2Loading: false,
  signupStep2Result: null,
  signupStep2Error: null,

  verifyLoading: false,
  verifyResult: null,
  verifyError: null,

  sendOtpLoading: false,
  sendOtpResult: null,
  sendOtpError: null,

  userLoading: true,
  user: null,
  verify: false,
  userType: null,
  userError: null,

  forgotPasswordLoading: false,
  forgotPasswordResult: null,
  forgotPasswordError: null,

  checkOtpLoading: false,
  checkOtpResult: null,
  checkOtpError: null,

  resetPasswordLoading: false,
  resetPasswordResult: null,
  resetPasswordError: null,

  removeTokenResult: false,

  preSignupLoading: false,
  preSignupResult: null,
  preSignupError: null,
};

export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const result = await authService.login(ISO, data);
    if (result && result.access_token) {
      localStorage.setItem("access_token", JSON.stringify(result.access_token));
    }
    return result;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const signupShipperStep1 = createAsyncThunk(
  "auth/signupShipperStep1",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const result = await authService.signup(ISO, data);
      if (result && result.access_token) {
        localStorage.setItem("access_token", JSON.stringify(result.access_token));
      }
      return result;
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signupCompanyStep1 = createAsyncThunk(
  "auth/signupCompanyStep1",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const result = await authService.signup(ISO, data);
      if (result && result.access_token) {
        localStorage.setItem("access_token", JSON.stringify(result.access_token));
      }
      return result;
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const signupStep2 = createAsyncThunk("auth/signupStep2", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const result = await authService.signup(ISO, data);
    if (result && result.access_token) {
      localStorage.setItem("access_token", JSON.stringify(result.access_token));
    }
    return result;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const verify = createAsyncThunk("auth/verify", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await authService.verify(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const sendOtp = createAsyncThunk("auth/sendOtp", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await authService.sendOtp(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getUser = createAsyncThunk("auth/getUser", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await authService.getUser(ISO, TOKEN);
  } catch (error) {
    const message = {
      message: "TOKEN IS NOT CORRECT",
      status: error?.response?.status,
    };
    return thunkAPI.rejectWithValue(message);
  }
});

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await authService.forgotPassword(ISO, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const checkOtp = createAsyncThunk("auth/checkOtp", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await authService.checkOtp(ISO, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const resetPassword = createAsyncThunk("auth/resetPassword", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const result = await authService.resetPassword(ISO, data);
    if (result && result.access_token) {
      localStorage.setItem("access_token", JSON.stringify(result.access_token));
    }
    return result;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    const result = await authService.logout(ISO, TOKEN);
    if (result) {
      localStorage.removeItem("access_token");
    }
    return result;
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const preSignup = createAsyncThunk("auth/preSignup", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await authService.preSignup(ISO, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const removeToken = createAsyncThunk("auth/removeToken", async (_, thunkAPI) => {
  localStorage.removeItem("access_token");
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetLogin: (state) => {
      state.loginLoading = false;
      state.loginResult = null;
      state.loginError = false;
    },
    resetSignupShipperStep1: (state) => {
      state.signupShipperStep1Loading = false;
      state.signupShipperStep1Result = null;
      state.signupShipperStep1Error = null;
    },
    resetSignupCompanyStep1: (state) => {
      state.signupCompanyStep1Loading = false;
      state.signupCompanyStep1Result = null;
      state.signupCompanyStep1Error = null;
    },
    resetSignupStep2: (state) => {
      state.signupStep2Loading = false;
      state.signupStep2Result = null;
      state.signupStep2Error = null;
    },
    resetVerify: (state) => {
      state.verifyLoading = false;
      state.verifyResult = null;
      state.verifyError = null;
    },
    resetSendOtp: (state) => {
      state.sendOtpLoading = false;
      state.sendOtpResult = null;
      state.sendOtpError = null;
    },
    resetUser: (state) => {
      state.userLoading = true;
      state.user = null;
      state.verify = false;
      state.userType = null;
      state.userError = null;
    },
    resetForgotPassword: (state) => {
      state.forgotPasswordLoading = false;
      state.forgotPasswordResult = null;
      state.forgotPasswordError = null;
    },
    resetCheckOtp: (state) => {
      state.checkOtpLoading = false;
      state.checkOtpResult = null;
      state.checkOtpError = null;
    },
    resetResetPassword: (state) => {
      state.resetPasswordLoading = false;
      state.resetPasswordResult = null;
      state.resetPasswordError = null;
    },
    resetRemoveToken: (state) => {
      state.removeTokenResult = false;
    },
    resetPreSignup: (state) => {
      state.preSignupLoading = false;
      state.preSignupResult = null;
      state.preSignupError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //////////////////////////
      .addCase(login.pending, (state) => {
        state.loginLoading = true;
        state.loginResult = null;
        state.loginError = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.loginResult = action.payload;
        state.access_token = action.payload.access_token;
        state.loginError = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.loginLoading = false;
        state.loginResult = null;
        state.loginError = action.payload;
      })
      //////////////////////////
      .addCase(signupShipperStep1.pending, (state) => {
        state.signupShipperStep1Loading = true;
        state.signupShipperStep1Result = null;
        state.signupShipperStep1Error = null;
      })
      .addCase(signupShipperStep1.fulfilled, (state, action) => {
        state.signupShipperStep1Loading = false;
        state.signupShipperStep1Result = action.payload;
        state.signupShipperStep1Error = null;
      })
      .addCase(signupShipperStep1.rejected, (state, action) => {
        state.signupShipperStep1Loading = false;
        if (
          action.payload?.errors &&
          !action.payload?.errors?.["company.name"]?.[0] &&
          !action.payload?.errors?.branch_id?.[0] &&
          !action.payload?.errors?.industry_id?.[0] &&
          !action.payload?.errors?.average_monthly_shipment?.[0]
        ) {
          state.signupShipperStep1Result = "First step completed successfully!";
        } else {
          state.signupShipperStep1Result = null;
        }
        state.signupShipperStep1Error = action.payload;
      })
      .addCase(signupCompanyStep1.pending, (state) => {
        state.signupCompanyStep1Loading = true;
        state.signupCompanyStep1Result = null;
        state.signupCompanyStep1Error = null;
      })
      .addCase(signupCompanyStep1.fulfilled, (state, action) => {
        state.signupCompanyStep1Loading = false;
        state.signupCompanyStep1Result = action.payload;
        state.signupCompanyStep1Error = null;
      })
      .addCase(signupCompanyStep1.rejected, (state, action) => {
        state.signupCompanyStep1Loading = false;
        if (
          action.payload?.errors &&
          !action.payload?.errors?.name?.[0] &&
          !action.payload?.errors?.branch_id?.[0] &&
          !action.payload?.errors?.phone?.[0] &&
          !action.payload?.errors?.phone_index_id?.[0]
        ) {
          state.signupCompanyStep1Result = "First step completed successfully!";
        } else {
          state.signupCompanyStep1Result = null;
        }
        state.signupCompanyStep1Error = action.payload;
      })
      //////////////////////////
      .addCase(signupStep2.pending, (state) => {
        state.signupStep2Loading = true;
        state.signupStep2Result = null;
        state.signupStep2Error = null;
      })
      .addCase(signupStep2.fulfilled, (state, action) => {
        state.signupStep2Loading = false;
        state.signupStep2Result = action.payload;
        // add acces token
        state.access_token = action.payload.access_token;
        state.signupStep2Error = null;
      })
      .addCase(signupStep2.rejected, (state, action) => {
        state.signupStep2Loading = false;
        state.signupStep2Result = null;
        state.signupStep2Error = action.payload;
      })
      //////////////////////////
      .addCase(verify.pending, (state) => {
        state.verifyLoading = true;
        state.verifyResult = null;
        state.verifyError = null;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.verifyLoading = false;
        state.verifyResult = action.payload;
        state.verifyError = null;
      })
      .addCase(verify.rejected, (state, action) => {
        state.verifyLoading = false;
        state.verifyResult = null;
        state.verifyError = action.payload;
      })
      //////////////////////////
      .addCase(sendOtp.pending, (state) => {
        state.sendOtpLoading = true;
        state.sendOtpResult = null;
        state.sendOtpError = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.sendOtpLoading = false;
        state.sendOtpResult = action.payload;
        state.sendOtpError = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.sendOtpLoading = false;
        state.sendOtpResult = null;
        state.sendOtpError = action.payload;
      })
      //////////////////////////
      .addCase(getUser.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userLoading = false;
        state.user = action.payload;
        state.verify =
          action.payload.type.id === 3 || action.payload.type.id === 4
            ? action.payload.email_verified
            : action.payload.phone_verified;
        state.userType = action.payload.type.id;
        state.userError = null;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.userLoading = false;
        state.user = null;
        state.verify = false;
        state.userType = null;
        state.userError = action.payload;
      })
      //////////////////////////
      .addCase(removeToken.fulfilled, (state) => {
        state.removeTokenResult = true;
        state.access_token = null;
        state.user = null;
        state.verify = false;
        state.userType = null;
      })
      //////////////////////////
      .addCase(forgotPassword.pending, (state) => {
        state.forgotPasswordLoading = true;
        state.forgotPasswordResult = null;
        state.forgotPasswordError = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordResult = action.payload;
        state.forgotPasswordError = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.forgotPasswordLoading = false;
        state.forgotPasswordResult = null;
        state.forgotPasswordError = action.payload;
      })
      //////////////////////////
      .addCase(checkOtp.pending, (state) => {
        state.checkOtpLoading = true;
        state.checkOtpResult = null;
        state.checkOtpError = null;
      })
      .addCase(checkOtp.fulfilled, (state, action) => {
        state.checkOtpLoading = false;
        state.checkOtpResult = action.payload;
        state.checkOtpError = null;
      })
      .addCase(checkOtp.rejected, (state, action) => {
        state.checkOtpLoading = false;
        state.checkOtpResult = null;
        state.checkOtpError = action.payload;
      })
      //////////////////////////
      .addCase(resetPassword.pending, (state) => {
        state.resetPasswordLoading = true;
        state.resetPasswordResult = null;
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.resetPasswordLoading = false;
        state.resetPasswordResult = action.payload;
        state.access_token = action.payload.access_token;
        state.resetPasswordError = null;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.resetPasswordLoading = false;
        state.resetPasswordResult = null;
        state.resetPasswordError = action.payload;
      })
      //////////////////////////
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.user = null;
        state.verify = null;
        state.access_token = null;
        state.error = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = action.payload;
      })

      .addCase(preSignup.pending, (state) => {
        state.preSignupLoading = true;
        state.preSignupResult = null;
        state.preSignupError = null;
      })
      .addCase(preSignup.fulfilled, (state, action) => {
        state.preSignupLoading = false;
        state.preSignupResult = action.payload;
        state.preSignupError = null;
      })
      .addCase(preSignup.rejected, (state, action) => {
        state.preSignupLoading = false;
        state.preSignupResult = null;
        state.preSignupError = action.payload;
      });
  },
});

export const {
  resetLogin,
  resetSignupShipperStep1,
  resetSignupCompanyStep1,
  resetSignupStep2,
  resetVerify,
  resetSendOtp,
  resetUser,
  resetForgotPassword,
  resetCheckOtp,
  resetResetPassword,
  resetRemoveToken,
  resetPreSignup,
} = authSlice.actions;
export default authSlice.reducer;
