import React, { Fragment, useState } from "react";
import "./ProfileInfo.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { MdModeEditOutline } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { FaMobileAlt } from "react-icons/fa";
import { BsPersonVcard } from "react-icons/bs";

import ProfileInfoItem from "./ProfileInfoItem/ProfileInfoItem";
import DTextButton from "../../../Form/DButtons/DTextButton/DTextButton";
import EditProfileModal from "./components/EditProfileModal/EditProfileModal";
import { findPhoneData } from "../../../../helper/findPhoneData";

const ProfileInfo = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const [showEditModal, setShowEditModal] = useState(false);

  const userMobileIndex = findPhoneData(getPhoneIndexesResult, user?.phone_index_id, true);

  const userType = () => {
    switch (user?.type?.id) {
      case 6:
        return t("tms.desc.Carrier");
      case 4:
        return t("tms.desc.Carrier company");
      case 3:
        return t("tms.desc.Shipper");
      default:
        break;
    }
  };

  return (
    <Fragment>
      <div id='profileInfo'>
        <div className='userNameAndTypeContainer'>
          <div className='userName'>{user?.name} </div>
          <div className='userType'>{userType()}</div>
        </div>
        <div className='userInfo'>
          <div className='userInfoItems'>
            {user.email && (
              <ProfileInfoItem max={30} icon={<CiMail />} text={user && user.email && user.email} />
            )}

            <ProfileInfoItem
              icon={<FaMobileAlt />}
              text={userMobileIndex + " " + user?.phone}
              max={15}
            />

            {user.legal_id && <ProfileInfoItem icon={<BsPersonVcard />} text={user.legal_id} />}
          </div>
          <div className='userInfoEdit'>
            <DTextButton
              onClick={() => {
                setShowEditModal(true);
              }}
              rightIcon={<MdModeEditOutline />}
              title={t("tms.forms.buttons.edit")}
            />
          </div>
        </div>
      </div>
      <EditProfileModal isOpen={showEditModal} isClose={() => setShowEditModal(false)} />
    </Fragment>
  );
};

export default ProfileInfo;
