import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customerService from "./customerService";
// Result
const initialState = {
  getCustomersLoading: true,
  getCustomersResult: null,
  getCustomersSelect: null,
  getCustomersMeta: null,
  getCustomersError: null,

  getCustomerLoading: true,
  getCustomerResult: null,
  getCustomerError: null,

  createCustomerLoading: false,
  createCustomerResult: null,
  createCustomerError: null,

  updateCustomerLoading: false,
  updateCustomerResult: null,
  updateCustomerError: null,

  deleteCustomerLoading: false,
  deleteCustomerResult: null,
  deleteCustomerError: null,

  getIndustriesLoading: false,
  getIndustriesResult: null,
  getIndustriesSelect: null,
  getIndustriesError: null,
};

export const getCustomers = createAsyncThunk("customer/getCustomers", async (data, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await customerService.getCustomers(ISO, TOKEN, data);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getCustomer = createAsyncThunk("customer/getCustomer", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await customerService.getCustomer(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerService.createCustomer(ISO, TOKEN, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (data, thunkAPI) => {
    try {
      const ISO = thunkAPI.getState().lang.ISO;
      const TOKEN = thunkAPI.getState().auth.access_token;
      return await customerService.updateCustomer(ISO, TOKEN, data.id, data);
    } catch (error) {
      const message = (error.response && error.response.data) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCustomer = createAsyncThunk("customer/deleteCustomer", async (id, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    const TOKEN = thunkAPI.getState().auth.access_token;
    return await customerService.deleteCustomer(ISO, TOKEN, id);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getIndustries = createAsyncThunk("customer/getIndustries", async (_, thunkAPI) => {
  try {
    const ISO = thunkAPI.getState().lang.ISO;
    return await customerService.getIndustries(ISO);
  } catch (error) {
    const message = (error.response && error.response.data) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    resetGetCustomers: (state) => {
      state.getCustomersLoading = true;
      state.getCustomersResult = null;
      state.getCustomersSelect = null;
      state.getCustomersMeta = null;
      state.getCustomersError = null;
    },
    resetGetCustomer: (state) => {
      state.getCustomerLoading = true;
      state.getCustomerResult = null;
      state.getCustomerError = null;
    },
    resetCreateCustomer: (state) => {
      state.createCustomerLoading = false;
      state.createCustomerResult = null;
      state.createCustomerError = null;
    },
    resetUpdateCustomer: (state) => {
      state.updateCustomerLoading = false;
      state.updateCustomerResult = null;
      state.updateCustomerError = null;
    },
    resetDeleteCustomer: (state) => {
      state.deleteCustomerLoading = false;
      state.deleteCustomerResult = null;
      state.deleteCustomerError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomers.pending, (state) => {
        state.getCustomersLoading = true;
        state.getCustomersResult = null;
        state.getCustomersSelect = null;
        state.getCustomersMeta = null;
        state.getCustomersError = null;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.getCustomersLoading = false;
        state.getCustomersResult = action.payload.data;
        state.getCustomersSelect = action.payload.data.map((item) => ({
          value: item,
          label: item?.name || "-",
        }));
        state.getCustomersMeta = action.payload.meta;
        state.getCustomersError = null;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.getCustomersLoading = false;
        state.getCustomersResult = null;
        state.getCustomersSelect = null;
        state.getCustomersMeta = null;
        state.getCustomersError = action.payload;
      })

      .addCase(getCustomer.pending, (state) => {
        state.getCustomerLoading = true;
        state.getCustomerResult = null;
        state.getCustomerError = null;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.getCustomerLoading = false;
        state.getCustomerResult = action.payload;
        state.getCustomerError = null;
      })
      .addCase(getCustomer.rejected, (state, action) => {
        state.getCustomerLoading = false;
        state.getCustomerResult = null;
        state.getCustomerError = action.payload;
      })

      .addCase(createCustomer.pending, (state) => {
        state.createCustomerLoading = true;
        state.createCustomerResult = null;
        state.createCustomerError = null;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.createCustomerLoading = false;
        state.createCustomerResult = action.payload;
        state.createCustomerError = null;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.createCustomerLoading = false;
        state.createCustomerResult = null;
        state.createCustomerError = action.payload;
      })

      .addCase(updateCustomer.pending, (state) => {
        state.updateCustomerLoading = true;
        state.updateCustomerResult = null;
        state.updateCustomerError = null;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.updateCustomerLoading = false;
        state.updateCustomerResult = action.payload;
        state.updateCustomerError = null;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.updateCustomerLoading = false;
        state.updateCustomerResult = null;
        state.updateCustomerError = action.payload;
      })

      .addCase(deleteCustomer.pending, (state) => {
        state.deleteCustomerLoading = true;
        state.deleteCustomerResult = null;
        state.deleteCustomerError = null;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.deleteCustomerLoading = false;
        state.deleteCustomerResult = action.payload;
        state.deleteCustomerError = null;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.deleteCustomerLoading = false;
        state.deleteCustomerResult = null;
        state.deleteCustomerError = action.payload;
      })

      .addCase(getIndustries.pending, (state) => {
        state.getIndustriesLoading = true;
        state.getIndustriesResult = null;
        state.getIndustriesSelect = null;
        state.getIndustriesError = null;
      })
      .addCase(getIndustries.fulfilled, (state, action) => {
        state.getIndustriesLoading = false;
        state.getIndustriesResult = action.payload;
        state.getIndustriesSelect = action.payload.map((item) => ({
          value: item,
          label: item?.name || "-",
        }));
        state.getIndustriesError = null;
      })
      .addCase(getIndustries.rejected, (state, action) => {
        state.getIndustriesLoading = false;
        state.getIndustriesResult = null;
        state.getIndustriesSelect = null;
        state.getIndustriesError = action.payload;
      });
  },
});

export const {
  resetGetCustomers,
  resetGetCustomer,
  resetCreateCustomer,
  resetUpdateCustomer,
  resetDeleteCustomer,
} = customerSlice.actions;
export default customerSlice.reducer;
