import React from "react";
import "./ShipperNavItem.scss";

import { NavLink } from "react-router-dom";

const ShipperNavItem = ({ to, title, disabled }) => {
  return (
    <li id='ShipperNavItem'>
      <NavLink className={disabled ? "disabled" : ""} to={to}>
        {title}
      </NavLink>
    </li>
  );
};

export default ShipperNavItem;
