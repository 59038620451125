import React from "react";
import "./SelectDriverField.scss";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { FaListUl } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoMdPerson } from "react-icons/io";
import { findPhoneData } from "../../../../helper/findPhoneData";

import Character from "../../../UI/Character/Character";

const SelectDriverField = ({ driver, onClick }) => {
  const { t } = useTranslation();
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const driverPhoneIndex = findPhoneData(getPhoneIndexesResult, driver?.phone_index_id, true);

  return (
    <td id='SelectDriverField' onClick={onClick}>
      {driver ? (
        <div className='SelectDriverFieldContainer'>
          <div className='userIcon'>
            <IoMdPerson />
          </div>

          <div className='content'>
            <div className='text'>
              <h3>
                <Character title={driver.name && driver.name} max={40} />
              </h3>
              <p>
                <Character title={driverPhoneIndex + " " + driver.phone} max={20} />
              </p>
            </div>

            <div className='icon'>
              <FaListUl />
            </div>
          </div>
        </div>
      ) : (
        <div className='noDriverContainer'>
          <div className='noDriverContainerContent'>
            <h3>{t("tms.tables.buttons.Select driver")}</h3>
            <div className='icon'>
              <IoMdAddCircleOutline />
            </div>
          </div>
        </div>
      )}
    </td>
  );
};

export default SelectDriverField;
