import React, { Fragment, useState } from "react";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";
import { findPhoneData } from "../../../../../../helper/findPhoneData";

import EditCustomerContactModal from "../../EditCustomerContactModal/EditCustomerContactModal";
import DeleteCustomerContactModal from "../../DeleteCustomerContactModal/DeleteCustomerContactModal";
import RowField from "../../../../../Form/TableFields/RowField/RowField";
import TextField from "./../../../../../Form/TableFields/TextField/TextField";
import MoreField from "../../../../../Form/TableFields/MoreField/MoreField";

const CustomerContactTableItem = ({ item, id }) => {
  const { t } = useTranslation();
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const contactPhoneIndex = findPhoneData(getPhoneIndexesResult, item?.phone_index_id, true);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField>
        <TextField label={item && item.name ? item.name : "-"} />
        <TextField label={item && item.position ? item.position?.name : "-"} />
        <TextField label={item?.phone ? contactPhoneIndex + " " + item.phone : "-"} />
        <TextField label={item && item.email ? item.email : "-"} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='ContactTableMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>

      {openEditModal && (
        <EditCustomerContactModal
          isOpen={openEditModal}
          isClose={() => setOpenEditModal(false)}
          id={id}
          item={item}
        />
      )}
      {openDeleteModal && (
        <DeleteCustomerContactModal
          isOpen={openDeleteModal}
          isClose={() => setOpenDeleteModal(false)}
          id={id}
          contactId={item.id}
        />
      )}
    </Fragment>
  );
};

export default CustomerContactTableItem;
