import React, { Fragment, useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { manageTruck, resetManageTruck } from "./../../../../../../store/fleet/trucks/trucksSlice";
import { findPhoneData } from "../../../../../../helper/findPhoneData";

import RowField from "../../../../../Form/TableFields/RowField/RowField";
import DriverField from "./../../../../../Form/TableFields/DriverField/DriverField";
import CenterField from "../../../../../Form/TableFields/CenterField/CenterField";
import DLinkOffButton from "../../../../Components/DLinkOffButton/DLinkOffButton";
import DLinkToButton from "../../../../Components/DLinkToButton/DLinkToButton";
import TruckField from "../../../../../Form/TableFields/TruckField/TruckField";
import TextField from "./../../../../../Form/TableFields/TextField/TextField";
import LinkOffDriverModal from "./../../../LinkOffDriverModal/LinkOffDriverModal";

const SelectDriverModalTableItem = ({ driver, truckId }) => {
  const dispatch = useDispatch();

  const [openLinkOffModal, setOpenLinkOffModal] = useState(false);
  const { manageTruckLoading, manageTruckResult } = useSelector((state) => state.trucks);
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const driverPhoneIndex = findPhoneData(getPhoneIndexesResult, driver?.phone_index_id, true);

  const attachHandler = () => {
    const data = {
      truckId: truckId,
      driver_id: driver.id,
    };
    dispatch(manageTruck(data));
  };

  const detachHandler = () => {
    const data = {
      truckId: driver.truck && driver.truck.id,
      driver_id: null,
    };
    dispatch(manageTruck(data));
  };

  useEffect(() => {
    if (manageTruckResult) {
      dispatch(resetManageTruck());
    }
  }, [dispatch, manageTruckResult]);

  const findcurrentDriver = driver && driver.truck && driver.truck.id === truckId;

  return (
    <Fragment>
      <RowField
        style={
          findcurrentDriver && {
            backgroundColor: "#eef2fa",
            borderLeft: "1px solid #2b353a",
          }
        }
      >
        <DriverField driver={driver} />
        <TextField label={driver?.phone ? driverPhoneIndex + " " + driver?.phone : "-"} />
        <TextField label={driver?.totalOrders?.toString() || "-"} />
        <TruckField truck={driver.truck} />
        <CenterField>
          {driver.truck ? (
            <DLinkOffButton onClick={() => setOpenLinkOffModal(true)} />
          ) : (
            <DLinkToButton onClick={() => attachHandler()} />
          )}
        </CenterField>
      </RowField>
      <LinkOffDriverModal
        loading={manageTruckLoading}
        isOpen={openLinkOffModal}
        isClose={setOpenLinkOffModal}
        onClick={detachHandler}
      />
    </Fragment>
  );
};

export default SelectDriverModalTableItem;
