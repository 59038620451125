import React, { useEffect, useState } from "react";
import "./Calendar.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import moment from "moment/moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";

import { getShipperCalendar } from "../../../../store/orders/shipperCalendar/shipperCalendarSlice";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import DSearch from "../../../../componentsDashboard/FilterForms/DSearch/DSearch";
import FilterShippingTypeItem from "../../../../componentsDashboard/FilterForms/FilterShippingType/FilterShippingTypeItem/FilterShippingTypeItem";
import ShipperCalendar from "../../../../componentsDashboard/ShipperCalendar/ShipperCalendar";
import ShipperCalendarSelectDate from "../../../../componentsDashboard/ShipperCalendar/ShipperCalendarSelectDate/ShipperCalendarSelectDate";
import TableWrapper from "../../../../componentsDashboard/Form/TableFields/TableWrapper/TableWrapper";
import ShipperTitleSection from "../../../../componentsDashboard/ShipperTitleSection/ShipperTitleSection";

const Calendar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const SHIPPINGTYPEARRAY = [
    {
      label: t("shipper.filters.All"),
      id: null,
    },
    {
      label: t("shipper.menu.My shipments"),
      id: "my_shipment",
    },
    {
      label: t("shipper.menu.Quotations"),
      id: "quotation",
    },
  ];

  const { rejectOrderResult, takeOrderResult } = useSelector((state) => state.shipperOrders);
  const { ISO } = useSelector((state) => state.lang);
  moment.locale(ISO);

  const [keyword, setKeyword] = useState("");
  const [startDate, setStartDate] = useState(moment());
  const [shippingType, setShippingType] = useState(SHIPPINGTYPEARRAY[0]);

  useEffect(() => {
    dispatch(
      getShipperCalendar({
        keyword,
        shipment_type: shippingType.id,
        date: startDate.format("YYYY-MM-DD"),
      })
    );
  }, [dispatch, keyword, shippingType.id, startDate, rejectOrderResult, takeOrderResult]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setKeyword(keyword);
    }, 500);
    return () => clearInterval(timeout);
  }, [keyword, setKeyword]);

  return (
    <Wrapper documentTitle={t("shipper.titles.Calendar")}>
      <section id='CalendarShipper'>
        <ShipperTitleSection title={t("shipper.titles.Calendar")} />
        <div className='filtersContainer'>
          <div className='searchContainer'>
            <DSearch value={keyword} setValue={(e) => setKeyword(e)} />
            <ShipperCalendarSelectDate
              startDate={startDate}
              setStartDate={(e) => setStartDate(e)}
            />
          </div>
          <div className='shipmentTypeFilters'>
            {SHIPPINGTYPEARRAY.map((item) => (
              <FilterShippingTypeItem
                key={item.id}
                title={item.label}
                active={shippingType?.id === item?.id}
                onClick={() => setShippingType(item)}
              />
            ))}
          </div>
        </div>
        <TableWrapper>
          <tbody>
            <tr>
              <td>
                <ShipperCalendar startDate={startDate} />
              </td>
            </tr>
          </tbody>
        </TableWrapper>
      </section>
    </Wrapper>
  );
};

export default Calendar;
