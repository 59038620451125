import React, { Fragment, useState, useEffect } from "react";
import "./FinancialManagement.scss";

import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";
import { MdError } from "react-icons/md";

import moment from "moment";

import {
  getFinancialOrders,
  resetGetFinancialOrders,
} from "./../../../../store/orders/shipperFinancial/shipperFinancialSlice";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import MainTitle from "../../../../componentsDashboard/MainTitle/MainTitle";
import FinancialFilter from "../../../../componentsDashboard/ShipperFinancial/FinancialFilter/FinancialFilter";
import LoadingPage from "../../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import FinancialTable from "../../../../componentsDashboard/ShipperFinancial/FinancialTable/FinancialTable";
import AccountantEmail from "../../../../componentsDashboard/ShipperFinancial/AccountantEmail/AccountantEmail";
import EmptyList from "./../../../../componentsDashboard/UI/EmptyList/EmptyList";
import BookModal from "../../../../componentsDashboard/ShipperHeader/Components/BookShipment/BookModal/BookModal";
import PageNotFound from "../../../../componentsBids/PageNotFound/PageNotFound";
import AddVatModal from "../../../../componentsDashboard/LoadBoards/Modals/AddVatModal/AddVatModal";

const FinancialManagement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const {
    getFinancialOrdersLoading,
    getFinancialOrdersResult,
    getFinancialOrdersMeta,
    getFinancialOrdersError,
  } = useSelector((state) => state.shipperFinancial);

  const { getShipperSummaryLoading, getShipperSummaryResult } = useSelector(
    (state) => state.summary
  );
  const { user } = useSelector((state) => state.auth);

  const hasVat = user?.company?.legal_id;
  const isSuspended = user?.rejected_at;

  const [showVatModal, setShowVatModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [page, setPage] = useState(searchParams.get("page") || 1);
  const [keyword, setKeyword] = useState("");
  const [route, setRoute] = useState({});
  const [price, setPrice] = useState({});
  const [status, setStatus] = useState(null);
  const [date, setDate] = useState({});

  const handleClearFilters = () => {
    if (keyword) setKeyword("");
    if (Object.keys(route).length > 0) setRoute({});
    if (Object.keys(price).length > 0) setPrice({});
    if (status) setStatus(null);
    if (Object.keys(date).length > 0) setDate({});
  };

  useEffect(() => {
    const data = {
      page,
      keyword,
      pickup_address: route?.fromCountry?.label || null,
      dropoff_address: route?.toCountry?.label || null,
      min_price: price?.minPrice || null,
      max_price: price?.maxPrice || null,
      start_date:
        Object.keys(date).length > 0 && date?.startDate
          ? moment(date.startDate).format("YYYY-MM-DD")
          : null,
      end_date:
        Object.keys(date).length > 0 && date?.endDate
          ? moment(date.endDate).format("YYYY-MM-DD")
          : null,
      payment_status: status || null,
    };
    dispatch(getFinancialOrders(data));
    return () => {
      dispatch(resetGetFinancialOrders());
    };
  }, [dispatch, keyword, page, status, route, price, date]);

  return (
    <Wrapper documentTitle={t("shipper.titles.Billing")}>
      <section id='ShipperFinancialManagement'>
        <div className='ShipperFinancialManagementHeader'>
          <MainTitle title={t("shipper.titles.Billing")} />
          <AccountantEmail />
        </div>
        <div className='infoMessageContainer'>
          <div className='icon'>
            <MdError />
          </div>
          <div className='textContainer'>
            <h3>
              {t(
                "shipper.titles.You will only be able to view invoices generated after 21.10.2024."
              )}
            </h3>
            <p>
              {t(
                "shipper.desc.We apologize for any inconvenience this may cause, but please remember that our financial team is always available to assist you with any invoice-related questions."
              )}
            </p>
          </div>
        </div>
        {getShipperSummaryLoading ? (
          <LoadingPage />
        ) : getShipperSummaryResult?.orders_count === 0 ? (
          <EmptyList
            image={"/assets/images/dashboard/cover1.svg"}
            title={t("shipper.titles.No bookings")}
            description={t("shipper.desc.Add booking and take full control of your orders")}
            onClick={() =>
              isSuspended ? null : hasVat ? setShowCreateModal(true) : setShowVatModal(true)
            }
            disabled={isSuspended}
            buttonText={t("shipper.buttons.New request")}
            icon={<FiPlus />}
          />
        ) : (
          <Fragment>
            <FinancialFilter
              keyword={keyword}
              setKeyword={setKeyword}
              route={route}
              setRoute={setRoute}
              price={price}
              setPrice={setPrice}
              status={status}
              setStatus={setStatus}
              date={date}
              setDate={setDate}
              onClear={handleClearFilters}
            />
            {getFinancialOrdersLoading && <LoadingPage />}
            {getFinancialOrdersError && <PageNotFound />}
            {getFinancialOrdersResult && (
              <FinancialTable
                items={getFinancialOrdersResult}
                page={page}
                setPage={setPage}
                pageCount={getFinancialOrdersMeta.last_page}
                total={getFinancialOrdersMeta.total}
              />
            )}
          </Fragment>
        )}

        {showCreateModal && (
          <BookModal isOpen={showCreateModal} isClose={() => setShowCreateModal(false)} />
        )}
        {showVatModal && (
          <AddVatModal isOpen={showVatModal} isClose={() => setShowVatModal(false)} />
        )}
      </section>
    </Wrapper>
  );
};

export default FinancialManagement;
