import React, { Fragment, useState } from "react";
import "./BookShipment.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";

import DButton from "../../../Form/DButtons/DButton/DButton";
import DIconButton from "./../../../Form/DButtons/DIconButton/DIconButton";
import BookModal from "./BookModal/BookModal";
import AddVatModal from "../../../LoadBoards/Modals/AddVatModal/AddVatModal";

const BookShipment = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showVatModal, setShowVatModal] = useState(false);

  const hasVat = user?.company?.legal_id;
  const isSuspended = user?.rejected_at;

  return (
    <Fragment>
      <div id='BookShipment'>
        <div className='BookShipmentLarge'>
          <DButton
            disabled={isSuspended}
            title={t("shipper.buttons.Book a shipment")}
            rightIcon={<FiPlus />}
            onClick={() => (hasVat ? setShowCreateModal(true) : setShowVatModal(true))}
          />
        </div>

        <div className='BookShipmentMobile'>
          <DIconButton
            icon={<FiPlus />}
            onClick={() =>
              isSuspended ? null : hasVat ? setShowCreateModal(true) : setShowVatModal(true)
            }
          />
        </div>
      </div>

      {showCreateModal && (
        <BookModal isOpen={showCreateModal} isClose={() => setShowCreateModal(false)} />
      )}
      {showVatModal && <AddVatModal isOpen={showVatModal} isClose={() => setShowVatModal(false)} />}
    </Fragment>
  );
};

export default BookShipment;
