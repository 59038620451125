import React, { useState, useEffect } from "react";
import "./SignUpCompanyForm.scss";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { signupCompanyStep1, resetSignupCompanyStep1 } from "../../../../store/auth/authSlice";

import AuthFormWrapper from "../../Components/AuthFormWrapper/AuthFormWrapper";
import FormTitle from "../../Components/FormTitle/FormTitle";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import Button from "../../../Form/Button/Button";
import Input from "../../../Form/Inputs/Input";
import Label from "../../../Form/Label/Label";
import ErrorText from "../../../Form/ErrorText/ErrorText";
import SelectCountry from "../../../Form/Select/Components/SelectCountry/SelectCountry";
import PhoneInput from "../../../Form/Inputs/PhoneInput/PhoneInput";
import NotHaveAccount from "./../../../Form/NotHaveAccount/NotHaveAccount";

const SignUpCompanyForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { signupCompanyStep1Loading, signupCompanyStep1Result, signupCompanyStep1Error } =
    useSelector((state) => state.auth);

  const { countryId, phoneIndexValueId } = useSelector((state) => state.settings);
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (signupCompanyStep1Result) {
      const url = `/register/tms/auth?fullName=${fullName}&countryId=${countryId}&phoneIndex=${phoneIndexValueId}&phone=${phone}`;
      navigate(url);
      dispatch(resetSignupCompanyStep1());
    }
  }, [dispatch, navigate, signupCompanyStep1Result, fullName, countryId, phone, phoneIndexValueId]);

  useEffect(() => {
    return () => {
      dispatch(resetSignupCompanyStep1());
    };
  }, [dispatch]);

  const submitHandler = async () => {
    let data = {
      type_id: 4,
      branch_id: countryId,
      name: fullName,
      phone_index_id: phoneIndexValueId,
      phone: phone,
    };
    dispatch(signupCompanyStep1(data));
  };

  return (
    <AuthFormWrapper>
      <form id='SignUpCompanyForm' className='SignUpCompanyForm'>
        <FormTitle title={t("landing.auth.titles.Welcome to the future of logistics")}>
          <span>{t("landing.auth.titles.It just take 2 minutes to set up")}</span>
        </FormTitle>
        <ProgressBar />
        <div className='group'>
          <div>
            <Label text={t("landing.auth.form.Full name")} id='FullName' />
            <Input
              value={fullName}
              setValue={setFullName}
              placeholder={t("landing.auth.form.Enter full name")}
              id='FullName'
              error={signupCompanyStep1Error?.errors?.name?.[0] ?? null}
            />
            <ErrorText error={signupCompanyStep1Error?.errors?.name?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Phone number")} id='PhoneNumber' />
            <PhoneInput
              id='PhoneNumber'
              value={phone}
              setValue={setPhone}
              placeholder={t("landing.auth.form.Enter Phone number")}
              error={
                (signupCompanyStep1Error?.errors?.phone_index_id?.[0] ?? null) ||
                (signupCompanyStep1Error?.errors?.phone?.[0] ?? null)
              }
            />
            <ErrorText error={signupCompanyStep1Error?.errors?.phone_index_id?.[0] ?? null} />
            <ErrorText error={signupCompanyStep1Error?.errors?.phone?.[0] ?? null} />
          </div>
          <div>
            <Label text={t("landing.auth.form.Country")} />
            <SelectCountry
              placeholder={t("landing.auth.form.Choose Country")}
              error={signupCompanyStep1Error?.errors?.branch_id?.[0] ?? null}
            />
            <ErrorText error={signupCompanyStep1Error?.errors?.branch_id?.[0] ?? null} />
          </div>
        </div>

        <Button
          loading={signupCompanyStep1Loading}
          title={t("landing.auth.form.Next")}
          onClick={() => {
            submitHandler();
          }}
        />
        <NotHaveAccount
          desc={t("landing.auth.titles.Already have an account?")}
          link='/login'
          text={t("landing.auth.form.Sign in here")}
        />
      </form>
    </AuthFormWrapper>
  );
};

export default SignUpCompanyForm;
