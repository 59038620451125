import React from "react";
import "./SelectForm.scss";

import { useTranslation } from "react-i18next";
import Select from "react-select";

const SelectForm = ({
  options,
  name,
  value,
  setValue,
  placeholder,
  disabled,
  loading,
  clear,
  search,
  emptyText,
  loadingText,
  error,
  separator,
  filterOption,
}) => {
  const { t } = useTranslation();
  // const filterOption = (option, inputValue) => {
  //   const { value } = option;
  //   return value.name.toLowerCase().includes(inputValue.toLowerCase());
  // };

  return (
    <div id='SelectForm' className={error ? "error " : ""}>
      <Select
        className={separator ? "selectComponent separator" : "selectComponent"}
        classNamePrefix='select'
        placeholder={placeholder ? placeholder : ""}
        isDisabled={disabled ? disabled : false}
        isLoading={loading ? loading : false}
        isClearable={clear ? clear : false}
        isSearchable={search ? search : false}
        name={name}
        options={options ? options : []}
        value={value}
        onChange={(state) => setValue(state)}
        noOptionsMessage={() => <div>{emptyText || t("landing.form.label.No information")} </div>}
        loadingMessage={() => (
          <div>{loadingText ? loadingText : t("landing.form.label.Loading")}</div>
        )}
        // getOptionLabel={(option) => option.value.id}
        filterOption={filterOption ? filterOption : null}
        // defaultMenuIsOpen={true}
      />
    </div>
  );
};

export default SelectForm;
