import React from "react";
import "./Title.scss";

import { useTranslation } from "react-i18next";
import { IoArrowBack } from "react-icons/io5";
import parse from "html-react-parser";

const Title = ({ back, setStep }) => {
  const { t } = useTranslation();
  const handleBack = () => {
    setStep((prev) => prev - 1);
  };

  return (
    <div id='CarrierSubscribeFormTitle'>
      {back && (
        <div className='back' onClick={handleBack}>
          <IoArrowBack />
        </div>
      )}
      <h1>{parse(t("landing.landingCarrierSubscribe.Subscribe <span>it’s free</span>"))}</h1>
      {/* <h1>{t("landing.landingCarrierSubscribe.Subscribe now")}</h1> */}
    </div>
  );
};

export default Title;
