import React, { useEffect, useState } from "react";
import "./EditProfileModal.scss";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MdModeEditOutline } from "react-icons/md";

import { resetUpdateUser, updateUser } from "../../../../../../store/user/userSlice";

import DModal from "../../../../../Modal/DModal/DModal";
import DInputs from "../../../../../Form/DInputs/DInputs";

const EditProfileModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { updateUserLoading, updateUserResult, updateUserError } = useSelector(
    (state) => state.user
  );
  const [fullName, setFullName] = useState(user?.name || "");
  const [companyName, setCompanyName] = useState(user?.company.name || "");
  const [companyLegalId, setCompanyLegalId] = useState(user?.company?.legal_id || "");
  const [address, setAddress] = useState(user?.company?.address || "");

  const submitHandler = () => {
    const data = {
      name: fullName,
      company: {
        legal_id: companyLegalId,
        name: companyName,
        address: address,
      },
    };

    dispatch(updateUser(data));
  };
  useEffect(() => {
    if (updateUserResult) {
      dispatch(resetUpdateUser());
      isClose();
    }
  }, [dispatch, isClose, updateUserResult]);

  return (
    <DModal
      loading={updateUserLoading}
      isOpen={isOpen}
      isClose={isClose}
      submitText={t("tms.modals.buttons.Save")}
      status={"info"}
      icon={<MdModeEditOutline />}
      title={t("tms.modals.titles.Edit profile")}
      onClick={submitHandler}
    >
      <div id='editUserProfileModal'>
        <div className='fromGorup'>
          <DInputs
            id='fullNameUserProfile'
            value={fullName}
            setValue={setFullName}
            label={t("tms.modals.labels.Full name")}
            placeholder={t("tms.modals.placeholder.Enter full name")}
            required
            error={updateUserError?.errors?.name?.[0]}
          />

          <DInputs
            id='companyNameUserProfile'
            value={companyName}
            setValue={setCompanyName}
            required
            label={t("tms.modals.labels.Company name")}
            placeholder={t("tms.modals.placeholder.Enter company name")}
            error={updateUserError?.errors?.["company.name"]?.[0]}
          />
          <DInputs
            id='companyLegalIdUserProfile'
            value={companyLegalId}
            setValue={setCompanyLegalId}
            required
            label={t("tms.modals.labels.Company legal ID")}
            placeholder={t("tms.modals.placeholder.Enter company legal ID")}
            error={updateUserError?.errors?.["company.legal_id"]?.[0]}
          />
          <DInputs
            id='companyAddressUserProfile'
            value={address}
            setValue={setAddress}
            label={t("tms.modals.labels.Company address")}
            placeholder={t("tms.modals.placeholder.Enter company address")}
          />
        </div>
      </div>
    </DModal>
  );
};

export default EditProfileModal;
