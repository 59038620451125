import React, { useEffect } from "react";
import "./Step1.scss";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SelectableButton from "../../../../../componentsDashboard/CustomersPartners/Components/SelectableButton/SelectableButton";
import Title from "../Components/Title/Title";
// import SubTitle from "../Components/SubTitle/SubTitle";
import Footer from "../Components/Footer/Footer";
import ErrorText from "../../../../Form/ErrorText/ErrorText";
import Label from "../../../../Form/Label/Label";
import Input from "../../../../Form/Inputs/Input";
import SelectForm from "../../../../Form/Select/SelectForm";

const Step1 = ({
  fullName,
  setFullName,
  email,
  setEmail,
  country,
  setCountry,
  switcherValue,
  setSwitcherValue,
  selectedRoutes,
  setSelectedRoutes,
  selectedTrailerTypes,
  setSelectedTrailerTypes,
  handleSubmit,
  loading,
  error,
}) => {
  const { t } = useTranslation();

  const { getTrailerTypesRoadResult } = useSelector((state) => state.trailerType);
  const { getRoutesRoadListResult } = useSelector((state) => state.routes);
  const { getAllCountriesResult, getAllCountriesLoading, countryId, getCountriesResult } =
    useSelector((state) => state.settings);

  const routes = getRoutesRoadListResult?.filter((route) => route?.id <= 7 || route?.id === 11);

  const filterOption = (option, inputValue) => {
    if (option) {
      const { value } = option;
      return (
        value?.name?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
        value?.type?.name?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );
    }
  };

  const options =
    getAllCountriesResult &&
    getAllCountriesResult.map((item) => ({
      value: item,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              width: "15px",
              height: "15px",
              backgroundImage: `url(${item?.flag})`,
              backgroundPosition: "cover",
              backgroundRepeat: "no-repeat",
              marginRight: "5px",
            }}
          ></div>

          <p>{item?.name}</p>
        </div>
      ),
    }));

  useEffect(() => {
    if (!country) {
      const currentCountry = getCountriesResult?.find((item) => item?.id === countryId)?.country;
      if (currentCountry) {
        setCountry({
          value: getCountriesResult?.find((item) => item?.id === countryId)?.country?.code,
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundImage: `url(${currentCountry?.flag})`,
                  backgroundPosition: "cover",
                  backgroundRepeat: "no-repeat",
                  marginRight: "5px",
                }}
              ></div>

              <p>{currentCountry.name}</p>
            </div>
          ),
        });
      }
    }
  }, [getCountriesResult, country, countryId, setCountry]);

  return (
    <div id='CarrierSubscribeFormStep1'>
      <Title />
      {/* <SubTitle text={t("landing.landingCarrierSubscribe.Join the future")} /> */}
      <div className='formContainer'>
        <div className='mainForm'>
          <div className='switcher'>
            <div className='switcherItems'>
              <div
                className={`item ${switcherValue === "fleetOwner" && "active"}`}
                onClick={() => setSwitcherValue("fleetOwner")}
              >
                {t("landing.landingCarrierSubscribe.Fleet owner")}
              </div>
              <div
                className={`item ${switcherValue === "forwarder" && "active"}`}
                onClick={() => setSwitcherValue("forwarder")}
              >
                {t("landing.landingCarrierSubscribe.Forwarder")}
              </div>
            </div>
          </div>
          <div>
            <Label text={t("tms.modals.labels.Full name")} id={"CarrierSubscribeFullName"} />
            <Input
              id={"CarrierSubscribeFullName"}
              type='text'
              placeholder={t("tms.modals.placeholder.Enter full name")}
              value={fullName}
              setValue={setFullName}
              error={error?.errors?.fullname?.[0]}
            />
            <ErrorText error={error?.errors?.fullname?.[0]} />
          </div>
          <div>
            <Label
              text={t("landing.landingCarrierSubscribe.Contact email")}
              id={"CarrierSubscribeEmail"}
            />
            <Input
              id={"CarrierSubscribeEmail"}
              type='email'
              placeholder={t("landing.landingCarrierSubscribe.Enter email")}
              value={email}
              setValue={setEmail}
              error={error?.errors?.email?.[0]}
            />
            <ErrorText error={error?.errors?.email?.[0]} />
          </div>
          <div>
            <Label
              text={t("landing.landingCarrierSubscribe.Home country")}
              id={"CarrierSubscribeFormStep2Country"}
            />
            <SelectForm
              id={"CarrierSubscribeFormStep2Country"}
              value={country}
              setValue={setCountry}
              options={options}
              search
              filterOption={filterOption}
              loading={getAllCountriesLoading}
              error={error?.errors?.country?.[0]}
            />
            <ErrorText error={error?.errors?.country?.[0]} />
          </div>
          <div className='selectableButtonContainer'>
            <div className='label'>
              {t("landing.landingCarrierSubscribe.Choose preferred destinations")}
            </div>
            <div className='buttons'>
              {routes?.map((route) => (
                <SelectableButton
                  key={route?.id}
                  item={route}
                  selectedTypes={selectedRoutes}
                  setSelectedTypes={setSelectedRoutes}
                  selected={selectedRoutes.includes(route?.id)}
                />
              ))}
            </div>
            <ErrorText error={error?.errors?.routes?.[0]} />
          </div>
          <div className='selectableButtonContainer'>
            <div className='label'>
              {t("landing.landingCarrierSubscribe.Choose transportation types")}
            </div>
            <div className='buttons'>
              {getTrailerTypesRoadResult?.map((trailerType) => (
                <SelectableButton
                  key={trailerType?.id}
                  item={trailerType}
                  selectedTypes={selectedTrailerTypes}
                  setSelectedTypes={setSelectedTrailerTypes}
                  selected={selectedTrailerTypes.includes(trailerType?.id)}
                />
              ))}
            </div>
            <ErrorText error={error?.errors?.trailer_types?.[0]} />
          </div>
        </div>
        <Footer loading={loading} submitHandler={handleSubmit} />
      </div>
    </div>
  );
};

export default Step1;
