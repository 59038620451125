import React, { Fragment } from "react";
import "./ListView.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import ViewSwitcher from "../Components/UI/ViewSwitcher/ViewSwitcher";
import OrdersTable from "../Components/OrdersTable/OrdersTable";
import LoadingPage from "./../../../componentsAdditional/Loading/LoadingPage/LoadingPage";
import ListViewHeader from "../Components/ListViewHeader/ListViewHeader";
import ShipperTitleSection from "../../ShipperTitleSection/ShipperTitleSection";

const ListView = ({
  page,
  setPage,
  activeOrderId,
  setActiveOrderId,
  activeOrderIndex,
  setActiveOrderIndex,
  // filters
  keyword,
  setKeyword,
  status,
  setStatus,
  shippingType,
  setShippingType,
  dateRange,
  setDateRange,
  route,
  setRoute,
  trailerType,
  setTrailerType,
  productCategory,
  setProductCategory,
  onClear,
}) => {
  const { t } = useTranslation();
  const { getShipperSummaryLoading, getShipperSummaryResult } = useSelector(
    (state) => state.summary
  );

  return (
    <section id='shipperOrderListView'>
      {getShipperSummaryLoading ? (
        <LoadingPage />
      ) : (
        getShipperSummaryResult && (
          <Fragment>
            <div className='shipperOrderListViewheader'>
              <ShipperTitleSection title={t("shipper.titles.Active shipments")} />
              <ViewSwitcher />
            </div>

            <Fragment>
              <ListViewHeader
                keyword={keyword}
                setKeyword={setKeyword}
                status={status}
                setStatus={setStatus}
                shippingType={shippingType}
                setShippingType={setShippingType}
                dateRange={dateRange}
                setDateRange={setDateRange}
                route={route}
                setRoute={setRoute}
                trailerType={trailerType}
                setTrailerType={setTrailerType}
                productCategory={productCategory}
                setProductCategory={setProductCategory}
                onClear={onClear}
              />

              <OrdersTable
                page={page}
                setPage={setPage}
                activeOrderId={activeOrderId}
                setActiveOrderId={setActiveOrderId}
                activeOrderIndex={activeOrderIndex}
                setActiveOrderIndex={setActiveOrderIndex}
              />
            </Fragment>
          </Fragment>
        )
      )}
    </section>
  );
};

export default ListView;
