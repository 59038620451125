import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { getIndustries } from "../../../../../store/customer/customerSlice";

import SelectForm from "../../SelectForm";

const SelectIndustry = ({ value, setValue, error }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getIndustriesResult, getIndustriesSelect, getIndustriesLoading } = useSelector(
    (state) => state.customer
  );

  useEffect(() => {
    if (!getIndustriesResult) {
      dispatch(getIndustries());
    }
  }, [dispatch, getIndustriesResult]);

  return (
    <div id='SelectIndustry'>
      <SelectForm
        options={getIndustriesSelect}
        name='selectIndustry'
        value={value}
        setValue={setValue}
        placeholder={t("landing.form.placeholder.Select industry")}
        loading={getIndustriesLoading}
        clear={false}
        loadingText=''
        error={error}
      />
    </div>
  );
};

export default SelectIndustry;
