import React, { Fragment, useState } from "react";
import "./DriversTableItem.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FiMoreVertical, FiEdit2, FiTrash2 } from "react-icons/fi";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { findPhoneData } from "../../../../../helper/findPhoneData";

import EditDriversModal from "./../../EditDriversModal/EditDriversModal";
import DeleteDriversModal from "./../../DeleteDriversModal/DeleteDriversModal";

import TextField from "../../../../Form/TableFields/TextField/TextField";
import RowField from "../../../../Form/TableFields/RowField/RowField";
import MoreField from "../../../../Form/TableFields/MoreField/MoreField";
import TruckField from "../../../../Form/TableFields/TruckField/TruckField";
import DriverField from "../../../../Form/TableFields/DriverField/DriverField";

const DriversTableItem = ({ driver }) => {
  const { t } = useTranslation();
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const driverPhoneIndex = findPhoneData(getPhoneIndexesResult, driver?.phone_index_id, true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Fragment>
      <RowField>
        <DriverField driver={driver} />
        <TextField label={driver.phone ? driverPhoneIndex + " " + driver?.phone : "-"} />
        <TextField label={driver?.totalOrders?.toString() || "-"} />
        <TruckField truck={driver.truck} />
        <MoreField>
          <div className='icon' onClick={handleClick}>
            <FiMoreVertical />
          </div>
          <Menu
            id='OrdersTableItemMore'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenEditModal(true);
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <FiEdit2 />
                {t("tms.tables.buttons.Edit")}
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setOpenDeleteModal(true);
                handleClose();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: "red",
                }}
              >
                <FiTrash2 />
                {t("tms.tables.buttons.Delete")}
              </div>
            </MenuItem>
          </Menu>
        </MoreField>
      </RowField>
      <EditDriversModal isOpen={openEditModal} isClose={setOpenEditModal} driver={driver} />
      <DeleteDriversModal
        driverId={driver.id}
        isOpen={openDeleteModal}
        isClose={setOpenDeleteModal}
      />
    </Fragment>
  );
};

export default DriversTableItem;
