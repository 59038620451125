import React, { Fragment, useEffect, useState } from "react";
import "./Subscribe.scss";

import { useTranslation } from "react-i18next";
import { PiArrowBendRightDownBold } from "react-icons/pi";

import Wrapper from "../../../../componentsAdditional/Wrapper";
import CoverCarrierSubscribe from "../../../../componentsLanding/Landing/Covers/CoverCarrierSubscribe/CoverCarrierSubscribe";
import WhyUs from "../../../../componentsLanding/Landing/SubscribeCarrier/WhyUs/WhyUs";
import CarrierSubscribeForm from "../../../../componentsLanding/Landing/SubscribeCarrier/CarrierSubscribeForm/CarrierSubscribeForm";
import Button from "../../../../componentsLanding/Form/Button/Button";
import SubscribeModal from "../../../../componentsLanding/Landing/SubscribeCarrier/SubscribeModal/SubscribeModal";
import Hotjar from "@hotjar/browser";
import WebPlatformCarrier from "../../../../componentsLanding/Landing/WebPlatformCarrier/WebPlatformCarrier";

const Subscribe = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Hotjar.event("Carrier Subscribe Page View JS Code");
  }, []);

  return (
    <Fragment>
      <Wrapper documentTitle={t("landing.documentTitles.Subscribe")}>
        <div id='CarrierSubscribe'>
          <CoverCarrierSubscribe />
          <div className='bottomContent'>
            <WhyUs />
            <div className='CarrierSubscribeForm'>
              <CarrierSubscribeForm />
            </div>
            <div className='subscribeModalButton'>
              <Button
                title={t("landing.landingCarrierSubscribe.Subscribe for free")}
                onClick={() => setShowModal(true)}
              />
            </div>
          </div>
        </div>
        <div id='WebPlatformCarrierSubscribeTitle'>
          <div className='titleContainer'>
            <div className='text'>
              {t("landing.landingCarrierSubscribe.Interested in more solutions?")}
            </div>
            <div className='icon'>
              <PiArrowBendRightDownBold />
            </div>
          </div>
        </div>
        <WebPlatformCarrier />
      </Wrapper>
      <SubscribeModal isOpen={showModal} isClose={() => setShowModal(false)} />
    </Fragment>
  );
};

export default Subscribe;
