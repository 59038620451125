import React from "react";
import "./CoverCarrierSubscribe.scss";

import { useTranslation } from "react-i18next";

import FadeTextAnimation from "../../../UI/Animations/FadeTextAnimation/FadeTextAnimation";
import Marquee from "react-fast-marquee";

const CoverCarrierSubscribe = () => {
  const { t } = useTranslation();

  const partners = [
    { name: "Pepsico", iconUrl: "/assets/images/partners/pepsico.svg" },
    { name: "Ashley", iconUrl: "/assets/images/partners/ashley.svg" },
    { name: "Nestle", iconUrl: "/assets/images/partners/nestle.svg" },
    { name: "Mondelez", iconUrl: "/assets/images/partners/mondelez.svg" },
    { name: "Jacobs", iconUrl: "/assets/images/partners/jacobs.svg" },
    { name: "Borjomi", iconUrl: "/assets/images/partners/borjomi.svg" },
  ];
  // Repeat the array content to ensure enough items
  const repeatedPartners = Array(6).fill(partners).flat();
  const RenderItem = ({ item }) => (
    <div className='item'>
      <div className='itemTitle' title={item.name}>
        {item.iconUrl && <img src={item.iconUrl} alt={item.name ? item.name : ""} />}
      </div>
    </div>
  );
  return (
    <section
      id='CarrierSubscribeNowCover'
      className='CarrierSubscribeNowCover'
      style={{
        backgroundImage: `url(${`/assets/images/static/carrierSubscribeCover.png`})`,
      }}
    >
      <div className='blar'>
        <div className='wrapper'>
          <div className='container'>
            <div className='text'>
              <FadeTextAnimation
                text={t("landing.titles.landingMainTitles.Maximize your earning potential")}
              />
            </div>
          </div>
        </div>
        <div
          className='partnersMainContainer'
          style={{
            backgroundImage: `url(${`/assets/images/map/WorldMap.svg`})`,
          }}
        >
          <div className='wrapper'>
            <Marquee direction='right' autoFill={false} pauseOnHover={true} pauseOnClick={true}>
              {repeatedPartners.map((item, index) => (
                <RenderItem item={item} key={index} />
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoverCarrierSubscribe;
