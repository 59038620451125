import React, { useEffect } from "react";
import "./TrackingDistanceData.scss";

import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import "moment/locale/ka";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";

import { getOrderTracking } from "../../../../store/trackerManager/trackerManagerSlice";
import { FaArrowRight } from "react-icons/fa6";

import OceanProgressBar from "./OceanProgressBar/OceanProgressBar";
import AirProgressBar from "./AirProgressBar/AirProgressBar";

const TrackingDistanceData = ({ order }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { ISO } = useSelector((state) => state.lang);
  const { getOrderTrackingResult } = useSelector((state) => state.trackerManager);

  moment.locale(ISO);
  const isSubscribed = order?.transports?.find((i) => i?.subscribed);
  const showRoadProgress =
    (order?.type_group?.id === 1 || order?.type_group?.id === 3) &&
    order?.last_shipment_status_update;

  useEffect(() => {
    if (isSubscribed) {
      dispatch(getOrderTracking(order?.id));
    }
  }, [dispatch, isSubscribed, order?.id]);

  const progress = order?.last_shipment_status_update?.distance_covered_percent || 0;
  const updateDate = order?.last_shipment_status_update?.created_at || null;
  const estDelivery = order?.last_shipment_status_update?.est || null;
  const etaDelivery = isSubscribed ? getOrderTrackingResult?.estimated_arrival : null;
  const etdDelivery = isSubscribed ? getOrderTrackingResult?.estimated_departure : null;

  const startLocationType =
    order?.cargos?.[0]?.locations?.[0]?.type === "LOCALITY"
      ? "Facility"
      : order?.cargos?.[0]?.locations?.[0]?.type === "PORT"
      ? "Port"
      : "Airport";
  const finalLocationType =
    order?.cargos?.[0]?.locations?.[order?.cargos?.[0]?.locations?.length - 1]?.type === "LOCALITY"
      ? "Facility"
      : order?.cargos?.[0]?.locations?.[order?.cargos?.[0]?.locations?.length - 1]?.type === "PORT"
      ? "Port"
      : "Airport";

  return (
    <div id='ShipperTrackingSectionDistance'>
      <div className='content'>
        {(order?.type_group?.id === 2 || order?.type_group?.id === 3) && isSubscribed ? (
          <h3>
            {startLocationType} <FaArrowRight /> {finalLocationType}
          </h3>
        ) : (
          showRoadProgress && <h3>{t("shipper.forms.content.Distance covered")}</h3>
        )}
        <div>
          {estDelivery && (
            <h4>
              {t("shipper.forms.content.Estimated delivery")}: <span>{estDelivery || "-"}</span>
            </h4>
          )}
          {etdDelivery && ( // Translation needed
            <h4>
              {t("shipper.forms.content.Estimate time of departure")}:{" "}
              <span>
                {moment(getOrderTrackingResult?.estimated_departure)?.format("DD MMM") || "-"}
              </span>
            </h4>
          )}
          {etaDelivery && ( // Translation needed
            <h4>
              {t("shipper.forms.content.Estimate time of arrival")}:{" "}
              <span>
                {moment(getOrderTrackingResult?.estimated_arrival)?.format("DD MMM") || "-"}
              </span>
            </h4>
          )}
          {updateDate && order?.type_group?.id !== 2 && (
            <h5>
              {t("shipper.forms.content.Update")}: <span>{updateDate || "-"}</span>
            </h5>
          )}
        </div>
      </div>

      {order?.type_group?.id === 2 && isSubscribed ? (
        <OceanProgressBar order={order} />
      ) : order?.type_group?.id === 3 && isSubscribed ? (
        <AirProgressBar order={order} />
      ) : (
        showRoadProgress && (
          <div className='progressContainer'>
            <div
              className='color'
              style={{
                width: parseFloat(progress) > 100 ? "100%" : `${parseFloat(progress)}%`,
              }}
            >
              <div className='dot'></div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default TrackingDistanceData;
