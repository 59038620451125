import React from "react";
import "./DashboardHeader.scss";

import { FaPlusCircle } from "react-icons/fa";
import { HiSparkles } from "react-icons/hi2";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MainTitle from "../../MainTitle/MainTitle";
import DButton from "../../Form/DButtons/DButton/DButton";
import DGradientButton from "../../Form/DButtons/DGradientButton/DGradientButton";

const DashboardHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { getPreferenceResult } = useSelector((state) => state.preferences);

  return (
    <div id='DashboardHeader'>
      <MainTitle
        title={t("tms.titles.Dashboard Overview")}
        description={`${t("tms.desc.Hi")} ${user.name} ${t("tms.desc.welcome back !")}`}
      />
      <div className='DashboardHeaderButtons'>
        {getPreferenceResult &&
          Object.keys(getPreferenceResult).length > 0 &&
          getPreferenceResult?.order_count > 0 && (
            <DGradientButton
              title={`${getPreferenceResult?.order_count} ${t(
                "tms.forms.buttons.orders match your specified interests"
              )}`}
              rightIcon={<HiSparkles />}
              onClick={() => navigate("/dashboard/tms/load_boards?preferences=true")}
            />
          )}
        <DButton
          title={t("tms.forms.buttons.Add Booking")}
          rightIcon={<FaPlusCircle />}
          onClick={() => navigate("/dashboard/tms/orders/new")}
        />
      </div>
    </div>
  );
};

export default DashboardHeader;
