import React, { Fragment, useState, useEffect } from "react";
import "./AddDriversModal.scss";

import { useTranslation } from "react-i18next";
import { FiPlus } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import * as amplitude from "@amplitude/analytics-browser";

import { createDriver, resetCreateDriver } from "./../../../../store/fleet/drivers/driversSlice";
import { findPhoneData } from "../../../../helper/findPhoneData";

import DModal from "../../../Modal/DModal/DModal";
import DInputs from "../../../Form/DInputs/DInputs";
import DPhoneInput from "../../../Form/DPhoneInput/DPhoneInput";

const AddDriversModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { createDriverLoading, createDriverResult, createDriverError } = useSelector(
    (state) => state.drivers
  );
  const { user } = useSelector((state) => state.auth);
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneIndex, setPhoneIndex] = useState(null);

  const submitHandler = () => {
    const data = {
      name,
      phone: phoneNumber,
      phone_index_id: phoneIndex?.value?.id,
    };
    dispatch(createDriver(data));
  };

  const resetState = () => {
    setName("");
    setPhoneNumber("");
  };

  useEffect(() => {
    if (user?.phone_index_id) {
      const phoneIndexObj = findPhoneData(getPhoneIndexesResult, user?.phone_index_id);
      setPhoneIndex({
        value: phoneIndexObj,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                width: "15px",
                height: "15px",
                backgroundImage: `url(${phoneIndexObj?.flag})`,
                backgroundPosition: "cover",
                backgroundRepeat: "no-repeat",
                marginRight: "5px",
              }}
            ></div>
            <p>{phoneIndexObj?.phone_index}</p>
          </div>
        ),
      });
    }
  }, [getPhoneIndexesResult, user?.phone_index_id]);

  useEffect(() => {
    if (createDriverResult) {
      amplitude.track("TMS: Create Driver");
      dispatch(resetCreateDriver());
      resetState();
      isClose();
    }
  }, [dispatch, isClose, createDriverResult]);

  return (
    <Fragment>
      <DModal
        isOpen={isOpen}
        isClose={() => isClose(false)}
        title={t("tms.modals.titles.Add Driver")}
        submitText={t("tms.modals.buttons.Save")}
        icon={<FiPlus />}
        status='success'
        onClick={() => submitHandler()}
        loading={createDriverLoading}
      >
        <div id='AddDriversModal'>
          <div className='AddDriversModalGroup'>
            <DPhoneInput
              id='enterDriverPhoneAddDriver'
              label={t("tms.modals.labels.Phone number")}
              placeholder={t("tms.modals.placeholder.Enter phone number")}
              required
              value={phoneNumber}
              setValue={setPhoneNumber}
              indexValue={phoneIndex}
              setIndexValue={setPhoneIndex}
              error={
                createDriverError?.errors?.phone?.[0] ||
                createDriverError?.errors?.phone_index_id?.[0]
              }
            />
            <DInputs
              id='enterDriverFirstNameAddDriver'
              value={name}
              setValue={setName}
              required
              label={t("tms.modals.labels.Driver first name")}
              placeholder={t("tms.modals.placeholder.Enter first name")}
              error={createDriverError?.errors?.name?.[0] ?? null}
            />
          </div>
        </div>
      </DModal>
    </Fragment>
  );
};

export default AddDriversModal;
