import React from "react";
import "./InvoiceView.scss";

import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";

import { useMoney } from "./../../../../../helper/useMoney";
import { findPhoneData } from "../../../../../helper/findPhoneData";

const InvoiceView = ({
  order,
  name,
  date,
  currrencyCode,
  bankRequisites,
  transaction,
  invoiceWarning,
  invoiceDayNumber,
  email,
  phone,
  phoneIndex,
  address,
  comment,
  charges,
}) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);
  const { getInvoiceResult } = useSelector((state) => state.invoices);

  const clientPhoneIndex = findPhoneData(
    getPhoneIndexesResult,
    getInvoiceResult?.client?.phone_index_id,
    true
  );

  const validCharges =
    charges &&
    charges
      .filter((charge) => charge.total !== "")
      .map((charge) => ({ ...charge, total: parseFloat(charge.total) }));
  const totalSum = validCharges && validCharges.reduce((acc, charge) => acc + charge.total, 0);
  const formatedNumber = useMoney(totalSum || 0, "");
  const TableItem = ({ i }) => {
    return (
      <tr>
        <td>
          <h5>{i.name}</h5>
          {typeof i.vat !== "undefined" && i.vat > 0 && (
            <p>
              {t("tms.invoice.Including")} {i.vat}% {t("tms.invoice.VAT")}
            </p>
          )}
        </td>
        <td>
          <h5 style={{ textAlign: "center", whiteSpace: "nowrap" }}>{useMoney(+i.amount, "")}</h5>
        </td>
        <td>
          <h5 style={{ textAlign: "center", whiteSpace: "nowrap" }}>
            {useMoney((+i.amount * +i.vat) / 100 || 0, "")}
          </h5>
        </td>
        <td>
          <h5 style={{ textAlign: "right", whiteSpace: "nowrap" }}>
            {useMoney(+i.total || 0, "")}
          </h5>
        </td>
      </tr>
    );
  };
  return (
    <div id='InvoiceView'>
      {getInvoiceResult && (
        <div className='InvoiceViewWrapper'>
          <div className='header'>
            <div className='title'>
              {user?.company?.logo && (
                <div
                  className='companyLogo'
                  style={{ backgroundImage: `url(${user?.company?.logo})` }}
                ></div>
              )}
              <h1>{t("tms.invoice.Invoice")}</h1>
            </div>

            <ul className='list'>
              <li>
                <h4>{t("tms.invoice.Invoice")} #</h4>
                <h5>{name}</h5>
              </li>
              <li>
                <h4>{t("tms.invoice.Issue date")}</h4>
                <h5>{moment(date).format("DD/MM/YYYY")}</h5>
              </li>
            </ul>
          </div>
          <div className='info'>
            <div className='item'>
              <h3>{t("tms.invoice.Sender")}:</h3>
              {user?.company?.name && <h5>{user?.company?.name}</h5>}
              {user?.company?.legal_id && (
                <h4>
                  {t("tms.invoice.Company ID")}: <span>{user?.company?.legal_id}</span>
                </h4>
              )}
              {address && (
                <h4>
                  {t("tms.invoice.Address")}: <span>{address}</span>
                </h4>
              )}
              {phone && (
                <h4>
                  {t("tms.invoice.Mobile")}:
                  <span>
                    {phoneIndex?.value?.phone_index ? "+" + phoneIndex?.value?.phone_index : ""}{" "}
                    {phone}
                  </span>
                </h4>
              )}
              {email && (
                <h4>
                  {t("tms.invoice.Mail")}: <span>{email}</span>
                </h4>
              )}
            </div>

            <div className='item'>
              <h3>{t("tms.invoice.Bill to")}:</h3>
              {getInvoiceResult?.client?.name && <h5>{getInvoiceResult?.client?.name}</h5>}
              {getInvoiceResult?.client?.code && (
                <h4>
                  {t("tms.invoice.Company ID")}: <span>{getInvoiceResult?.client?.code}</span>
                </h4>
              )}
              {getInvoiceResult?.client?.address && (
                <h4>
                  {t("tms.invoice.Address")}: <span>{getInvoiceResult?.client?.address}</span>
                </h4>
              )}
              {getInvoiceResult?.client?.phone && (
                <h4>
                  {t("tms.invoice.Mobile")}:{" "}
                  <span>
                    {clientPhoneIndex || ""} {getInvoiceResult?.client?.phone}
                  </span>
                </h4>
              )}
              {getInvoiceResult?.client?.email && (
                <h4>
                  {t("tms.invoice.Mail")}: <span>{getInvoiceResult?.client?.email}</span>
                </h4>
              )}
            </div>
            <div className='item'>
              <h3>{t("tms.invoice.Service")}:</h3>
              <h5>{t("tms.invoice.Transportation")}</h5>
              {order?.transports?.length > 0 && (
                <h4>
                  {t("tms.invoice.Truck number")}: <span>{order?.transports[0]?.number}</span>
                </h4>
              )}
            </div>
          </div>
          <div className='table'>
            <div className='currency'>
              <p>
                {t("tms.invoice.Currency")}: <span>{currrencyCode && currrencyCode.label}</span>
              </p>
            </div>
            <table>
              <thead>
                <tr>
                  <th>
                    <h4 style={{ textAlign: "left" }}>{t("tms.invoice.Charges")}</h4>
                  </th>
                  <th>
                    <h4>{t("tms.invoice.Amount")}</h4>
                  </th>
                  <th>
                    <h4>{t("tms.invoice.VAT")}</h4>
                  </th>
                  <th>
                    <h4 style={{ textAlign: "right" }}>{t("tms.invoice.Total")}</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {charges &&
                  charges.length > 0 &&
                  charges.map((i, index) => <TableItem i={i} key={index} />)}
              </tbody>
            </table>
            {charges && charges.length === 0 && (
              <div className='noCharges'>
                <p>{t("tms.invoice.No charge")}</p>
              </div>
            )}
          </div>
          <div className='finance'>
            <div className='requisitesContainer'>
              {bankRequisites?.map((requisite, index) => (
                <div key={index} className='requisites'>
                  <h3>
                    {t("tms.invoice.Requisites")} {bankRequisites.length > 1 && `(${index + 1})`}
                  </h3>
                  <div className='item'>
                    <h4>{t("tms.invoice.Beneficiary")}:</h4>
                    <h5>{user?.company?.name}</h5>
                  </div>
                  <div className='item'>
                    <h4>{t("tms.invoice.Bank")}:</h4>
                    <h5>{requisite?.bankName}</h5>
                  </div>
                  <div className='item'>
                    <h4>{t("tms.invoice.Account")}:</h4>
                    <h5>{requisite?.bankACCN}</h5>
                  </div>
                  <div className='item'>
                    <h4>{t("tms.invoice.Bank code")}:</h4>
                    <h5>{requisite?.bankCode}</h5>
                  </div>
                </div>
              ))}
              {transaction && (
                <p>{t("tms.invoice.Please include the invoice number in the transaction")}</p>
              )}
            </div>

            <div className='card'>
              <div className='content'>
                <h3>{t("tms.invoice.Total due")}:</h3>
                <h1>
                  {currrencyCode && currrencyCode.label} {formatedNumber}
                </h1>
              </div>
              {invoiceWarning && (
                <p>
                  *{t("tms.invoice.Please pay within")} {invoiceDayNumber}{" "}
                  {t("tms.invoice.days of receiving this invoice.")}
                </p>
              )}
            </div>
          </div>
          {comment && (
            <div className='comment'>
              <h3>{t("tms.invoice.Comment")}</h3>
              <p>{comment}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default InvoiceView;
