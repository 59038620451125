import React from "react";
import "./ShipperTitleSection.scss";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MdOutlineWatchLater, MdOutlinePhoneIphone, MdMail } from "react-icons/md";

import { findPhoneData } from "../../helper/findPhoneData";

import MainTitle from "../MainTitle/MainTitle";

const ShipperTitleSection = ({ title, subtitle }) => {
  const { t } = useTranslation();

  const { user } = useSelector((state) => state.auth);
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const manager = user?.sale_manager;
  const phoneIndex = findPhoneData(getPhoneIndexesResult, manager?.phone_index_id, true);

  return (
    <div id='ShipperTitleSection'>
      <MainTitle title={title} description={subtitle} />
      <div className='managerContainer' style={{ borderColor: manager ? "#2e5aac" : "#b95000" }}>
        <div
          className='initials'
          style={{
            backgroundColor: manager ? "#2e5aac" : "#b95000",
            borderColor: manager ? "#2e5aac" : "#b95000",
          }}
        >
          {manager ? (
            <p>
              {manager?.name
                ?.split(" ")
                ?.map((i) => i[0])
                ?.join("")
                ?.toUpperCase()}
            </p>
          ) : (
            <MdOutlineWatchLater
              style={{
                color: "#b95000",
                backgroundColor: "#fff",
                borderRadius: "50%",
                fontSize: "24px",
              }}
            />
          )}
        </div>
        <div className='managerInfo'>
          {manager ? (
            <>
              <div className='topContainer'>
                <h1>{manager?.name || ""}</h1>
                <h2>{t("shipper.desc.Personal manager")}</h2>
              </div>
              <div className='bottomContainer'>
                <div className='managerContact'>
                  <div className='icon'>
                    <MdOutlinePhoneIphone />
                  </div>
                  <div className='contact'>{(phoneIndex || "") + " " + (manager?.phone || "")}</div>
                </div>
                <div className='managerContact'>
                  <div className='icon'>
                    <MdMail />
                  </div>
                  <div className='contact'>{manager?.email || ""}</div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h1>{t("shipper.desc.Your manager will call you soon")}</h1>
              <h2>
                {t("shipper.desc.We will give you a personal manager, who will call you soon")}
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShipperTitleSection;
