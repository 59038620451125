import React from "react";
import "./AirProgressBar.scss";

import { useSelector } from "react-redux";
import { IoAirplaneOutline } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { TbProgress } from "react-icons/tb";

const AirProgressBar = ({ order }) => {
  const { getOrderTrackingLoading } = useSelector((state) => state.trackerManager);

  const DATA = order?.cargos?.[0]?.locations;

  const progressWidth = 25;

  const A = DATA?.filter((i) => i?.type === "LOCALITY" && i?.direction === "A");
  const B = DATA?.filter((i) => i?.type === "LOCALITY" && i?.direction === "B");
  const dotsArr =
    A?.length > 0 && B?.length > 0
      ? [1, 25, 75, 99]
      : A?.length > 0 && B?.length === 0
      ? [1, 25, 99]
      : A?.length === 0 && B?.length > 0
      ? [1, 75, 99]
      : [1, 99];

  return getOrderTrackingLoading ? (
    <div></div>
  ) : (
    <div id='AirProgressBar'>
      {dotsArr?.map((dot, index) => {
        return (
          <div
            key={index}
            style={{
              left: `${dot}%`,
            }}
            className='dotContainer'
          >
            <div className='dot' />
          </div>
        );
      })}
      <div className='progressBar' />
      <div
        className='progressLine'
        style={{ width: order?.statusClient?.id === 4 ? "100%" : `${progressWidth}%` }}
      />
      {order?.statusClient?.id !== 4 && progressWidth >= 0 && progressWidth <= 99 && (
        <div
          style={{
            left: `${progressWidth}%`,
          }}
          className='progressIconContainer'
        >
          <div className='progressIcon'>
            <div className='icon'>
              <IoAirplaneOutline />
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "flex", gap: "4px" }}>
        {A?.length > 0 && (
          <div style={{ width: "25%" }}>
            <div className='shippingTypeInfoContainer'>
              <h6 className='shippingTypeInfo'>Land</h6>
              {progressWidth > 0 && progressWidth < 25 ? (
                <div className='icon progress'>
                  <TbProgress />
                </div>
              ) : (
                progressWidth >= 25 && (
                  <div className='icon'>
                    <FaCheckCircle />
                  </div>
                )
              )}
            </div>
          </div>
        )}
        <div
          style={{
            width:
              A?.length > 0 && B?.length > 0
                ? "50%"
                : A?.length > 0 || B?.length > 0
                ? "75%"
                : "100%",
          }}
        >
          <div className='shippingTypeInfoContainer'>
            <h6 className='shippingTypeInfo'>Air</h6>
            {A?.length > 0 && B?.length > 0 ? (
              progressWidth >= 25 && progressWidth < 75 ? (
                <div className='icon progress'>
                  <TbProgress />
                </div>
              ) : (
                progressWidth >= 75 && (
                  <div className='icon'>
                    <FaCheckCircle />
                  </div>
                )
              )
            ) : A?.length > 0 && B?.length === 0 ? (
              progressWidth >= 25 && progressWidth < 100 ? (
                <div className='icon progress'>
                  <TbProgress />
                </div>
              ) : (
                progressWidth === 100 && (
                  <div className='icon'>
                    <FaCheckCircle />
                  </div>
                )
              )
            ) : A?.length === 0 && B?.length > 0 ? (
              progressWidth > 0 && progressWidth <= 75 ? (
                <div className='icon progress'>
                  <TbProgress />
                </div>
              ) : (
                progressWidth > 75 && (
                  <div className='icon'>
                    <FaCheckCircle />
                  </div>
                )
              )
            ) : A?.length === 0 && B?.length === 0 && progressWidth > 0 && progressWidth <= 99 ? (
              <div className='icon progress'>
                <TbProgress />
              </div>
            ) : (
              progressWidth === 100 && (
                <div className='icon'>
                  <FaCheckCircle />
                </div>
              )
            )}
          </div>
        </div>
        {B?.length > 0 && (
          <div style={{ width: "25%" }}>
            <div className='shippingTypeInfoContainer'>
              <h6 className='shippingTypeInfo'>Land</h6>
              {progressWidth >= 75 && progressWidth <= 99 ? (
                <div className='icon progress'>
                  <TbProgress />
                </div>
              ) : (
                progressWidth === 100 && (
                  <div className='icon'>
                    <FaCheckCircle />
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AirProgressBar;
