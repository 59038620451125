import React, { Fragment, useState, useEffect } from "react";
import "./TruckAdditionalDetails.scss";

import { useTranslation } from "react-i18next";
import { FaUser } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

import { manageTruck, resetManageTruck } from "./../../../../../store/fleet/trucks/trucksSlice";
import { findPhoneData } from "../../../../../helper/findPhoneData";

import SelectDriverModal from "../../../FleetManagementDrivers/SelectDriverModal/SelectDriverModal";
import LinkOffDriverModal from "./../../../FleetManagementDrivers/LinkOffDriverModal/LinkOffDriverModal";
import SelectTrailerModal from "../../../FleetManagementTrailers/SelectTrailerModal/SelectTrailerModal";
import LinkOffTrailerModal from "../../../FleetManagementTrailers/LinkOffTrailerModal/LinkOffTrailerModal";
import Character from "./../../../../UI/Character/Character";
import DTextButton from "../../../../Form/DButtons/DTextButton/DTextButton";

const TruckAdditionalDetails = ({ truck }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openSelectDriverModal, setOpenSelectDriverModal] = useState(false);
  const [openLinkOffDriverModal, setOpenLinkOffDriverModal] = useState(false);

  const [openSelectTrailerModal, setOpenSelectTrailerModal] = useState(false);
  const [openLinkOffTrailerModal, setOpenLinkOffTrailerModal] = useState(false);

  const { manageTruckLoading, manageTruckResult } = useSelector((state) => state.trucks);
  const { getPhoneIndexesResult } = useSelector((state) => state.settings);

  const driverPhoneIndex = findPhoneData(getPhoneIndexesResult, truck?.driver?.phone_index_id, true);

  const detachDriverHandler = () => {
    const data = {
      truckId: truck.id,
      driver_id: null,
    };
    dispatch(manageTruck(data));
  };

  const detachTrailerHandler = () => {
    const data = {
      truckId: truck.id,
      trailer_id: null,
    };
    dispatch(manageTruck(data));
  };

  useEffect(() => {
    if (manageTruckResult) {
      dispatch(resetManageTruck());
    }
  }, [dispatch, manageTruckResult]);
  return (
    <Fragment>
      <div id='TruckAdditionalDetails'>
        <div className='TruckAdditionalDetailsHeader'>
          <div className='TruckAdditionalDetailsHeaderContainerItem'>
            <div
              className='cover'
              style={{
                backgroundImage: `url(${`/assets/images/dashboard/cover8.svg`})`,
              }}
            ></div>
            <div>
              <h1>
                <Character
                  title={truck.model ? `${truck?.model?.brand?.name} - ${truck?.model?.name}` : "-"}
                  max={30}
                />
              </h1>
              <p>{truck.registration_number || "-"}</p>
              {/* <div className='AddDowntime'>Add downtime</div> */}
            </div>
          </div>
          {truck.active ? (
            <div className='TruckAdditionalDetailsStatus'>{t("tms.desc.Active")}</div>
          ) : (
            <div className='TruckAdditionalDetailsStatusInactive'>{t("tms.desc.Inactive")}</div>
          )}
        </div>
        <div className='LinkedTrailer'>
          <h1>{t("tms.titles.Linked trailer")}</h1>
          <div className='LinkedTrailerContainer'>
            {truck.trailer ? (
              <div className='LinkedTrailerContainerItem'>
                <div
                  className='cover'
                  style={{
                    backgroundImage: truck.trailer
                      ? `url(${truck.trailer?.type?.trailer_icon})`
                      : `url(${`/assets/images/dashboard/cover7.svg`})`,
                  }}
                ></div>
                <div>
                  <h3>{truck.trailer?.type?.name && truck.trailer?.type?.name}</h3>
                  <p>
                    {truck.trailer.volume_capacity &&
                      truck.trailer.volume_capacity + ` (${t("tms.desc.M³")})`}
                  </p>
                </div>
              </div>
            ) : (
              <div className='NoTrailer'>{t("tms.desc.No trailer linked")}</div>
            )}
            <div className='action'>
              {/* <div
                className='actionChange'
                onClick={() => setOpenSelectTrailerModal(true)}
              >
                {truck.trailer ? "Change trailer" : "Link trailer"}
              </div> */}
              <DTextButton
                onClick={() => setOpenSelectTrailerModal(true)}
                title={
                  truck.trailer
                    ? t("tms.forms.buttons.Change trailer")
                    : t("tms.forms.buttons.Link trailer")
                }
              />
              {truck.trailer && (
                // <DLinkOffButton
                //   onClick={() => setOpenLinkOffTrailerModal(true)}
                // />
                <DTextButton
                  title={t("tms.forms.buttons.Unlink trailer")}
                  onClick={() => setOpenLinkOffTrailerModal(true)}
                />
              )}
            </div>
          </div>
        </div>
        <div className='LinkedDriver'>
          <h1>{t("tms.titles.Linked driver")}</h1>
          <div className='LinkedDriverContainer'>
            {truck.driver ? (
              <div className='LinkedDriverContainerItem'>
                <div className='icon'>
                  <FaUser />
                </div>
                <div>
                  <h3>
                    <Character title={truck.driver.name && truck.driver.name} max={20} />
                  </h3>
                  <p>{driverPhoneIndex + " " + truck?.driver?.phone}</p>
                </div>
              </div>
            ) : (
              <div className='NoDriver'>
                <p>{t("tms.desc.No driver linked")}</p>
              </div>
            )}
            <div className='action'>
              {/* <div
                className='actionChange'
                onClick={() => setOpenSelectDriverModal(true)}
              >
                {truck.driver ? "Change driver" : "Linked driver"}
              </div> */}
              <DTextButton
                onClick={() => setOpenSelectDriverModal(true)}
                title={
                  truck.driver
                    ? t("tms.forms.buttons.Change driver")
                    : t("tms.forms.buttons.Link driver")
                }
              />
              {truck.driver && (
                // <DLinkOffButton
                //   onClick={() => setOpenLinkOffDriverModal(true)}
                // />
                <DTextButton
                  title={t("tms.forms.buttons.Unlink driver")}
                  onClick={() => setOpenLinkOffDriverModal(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <SelectTrailerModal
        isOpen={openSelectTrailerModal}
        isClose={() => setOpenSelectTrailerModal(false)}
        truck={truck}
      />

      <LinkOffTrailerModal
        isOpen={openLinkOffTrailerModal}
        isClose={setOpenLinkOffTrailerModal}
        onClick={() => detachTrailerHandler()}
        loading={manageTruckLoading}
      />

      <SelectDriverModal
        isOpen={openSelectDriverModal}
        isClose={() => setOpenSelectDriverModal(false)}
        truck={truck}
      />
      <LinkOffDriverModal
        loading={manageTruckLoading}
        isOpen={openLinkOffDriverModal}
        isClose={setOpenLinkOffDriverModal}
        onClick={detachDriverHandler}
      />
    </Fragment>
  );
};

export default TruckAdditionalDetails;
