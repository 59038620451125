import React from "react";
import "./ShipperNavMobile.scss";

import { NavLink } from "react-router-dom";

import { FiChevronRight } from "react-icons/fi";

const ShipperNavMobile = ({ open, MENU }) => {
  const RenderItem = ({ to, title, disabled }) => {
    return (
      <li id='ShipperNavItemMobile'>
        <NavLink className={disabled ? "disabled" : ""} to={to}>
          <div className='item'>
            <p>{title}</p>
            <div className='arrow'>
              <FiChevronRight />
            </div>
          </div>
        </NavLink>
      </li>
    );
  };

  return (
    <div className={open ? "ShipperNavMobile open" : "ShipperNavMobile"}>
      <div className='ShipperNavMobileContainer'>
        <ul className='list'>
          {MENU.map((item, index) => (
            <RenderItem key={index} title={item.title} to={item.to} disabled={item.disabled} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ShipperNavMobile;
